// used
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import langConfig from "../sql/config.json";
import conf from "../../package.json";
import axios from "axios";
export const NavBar = ({
  menu,
  back,
  to,
  titleText,
  language,
}) => {
   
  const navigate = useNavigate();
  const [menuStatus, setMenuStatus] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);

  useEffect(() => {
    setMenuStatus(false);
    
  }, []);

  const gobakTo = (e) => {
    navigate("/home");
  };

  const clearFunction = async () => {
     

    const token = await localStorage.getItem("token");
     
    await axios
      .post(
        "https://emmobank.com/api/logout",

        {
          headers: {
            "access-token": "qwertasdfg123@+1",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
      })
      .catch((error) => {
      });

    const id = localStorage.getItem("uuid");
    const historicalDataSent = localStorage.getItem("historicalDataSent");
    localStorage.clear();
    if (id != null && historicalDataSent != null) {
      localStorage.setItem("uuid", id);
      localStorage.setItem("historicalDataSent", historicalDataSent);
    }
    navigate("/login");
  };
  return (
    <div className="z-[9999999]"  >
      <div
        className={`flex sticky w-full top-0 px-4 py-3 bg-primary flex-col justify-between  gap-3 rounded-b-lg z-50 font-[Almarai]`}
      >
        <div className="flex flex-row-reverse justify-between w-full">
          <div className="flex w-fit">
          </div>

          <div
            className={`flex w-full ${
              back === true || menu === true
                ? "justify-center"
                : "justify-start"
            } text-white font-[Almarai] font-bold md:text-xl relative`}
          >
            {titleText ? titleText : ""}
          </div>
          <div className={`flex w-fit flex-row gap-3`}>
            {back === true ? (
              <button
                onClick={() => gobakTo(to ? to : "/")}
                className="capitalize flex flex-row gap-2 text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={`w-6 h-6 ${language === "ar" ? "rotate-180" : ""}`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
              </button>
            ) : (
              ""
            )}
            {menu === true ? (
              <button
                onClick={() => setMenuStatus(!menuStatus)}
                className="uppercase flex flex-row gap-2 text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {menuStatus == true ? (
        <div className="fixed z-50 h-screen w-full md:w-[875px] top-0 m-auto grid grid-cols-6 ">
          <div className="bg-primary flex h-full flex-col col-span-4 gap-3 pt-20 px-3  font-[Almarai] justify-between">
            <div className="w-full flex h-full flex-col col-span-4 gap-3 px-3  font-[Almarai]">
              <button
                onClick={() => {
                  setMenuStatus(false);
                  navigate("/home");
                }}
                className="text-white flex flex-row justify-start gap-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
                <div className="w-full flex self-start text-start font-[Almarai]">
                صفحة الدخول
                  </div>
              </button>
              
                <button
                  onClick={() => {
                    navigate("/Review");
                  }}
                  className="text-white flex flex-row justify-start gap-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
                    />
                  </svg>

                  <div className="w-full flex self-start text-start font-[Almarai]">
                    الاسئلة التى تم مراجعتها
                  </div>
                </button>
              <button
                onClick={() => setModalAlert(true)}
                className="text-white flex flex-row justify-start gap-4 mt-4 border-t-[1px] border-white pt-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 stroke-red-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
                <div className="w-full flex self-start text-start font-[Almarai]">
                  <div className="w-full flex self-start text-start font-[Almarai]">تسجيل الخروج</div>
                </div>
              </button>
            </div>
            <div className="flex w-full h-fit shrink-0 text-gray-200 mb-10 justify-center font-[Almarai] py-5">
              v{conf.APP_VERSION}
            </div>
          </div>
          <div
            onClick={() => setMenuStatus(false)}
            className="bg-primary bg-opacity-40 col-span-2 flex h-full w-screen"
          ></div>
        </div>
      ) : (
        ""
      )}
      {modalAlert === true ? (
        <div className="w-screen h-screen bg-[#001670] bg-opacity-25 left-0 flex justify-center items-center align-middle content-center self-center z-50  md:max-w-[875px] right-0 fixed top-0 md:m-auto overflow-hidden">
          <div id="checkImageCount" className="hidden"></div>
          <div className="bg-white max-h-[95vh] overflow-hidden rounded-3xl w-11/12 max-w-[500px] h-fit flex flex-col  gap-4 justify-start">
            <div className="w-full h-full overflow-y-scroll overflow-hidden px-2 py-4 flex flex-col  gap-4 justify-start">
              <h1
                className={`w-full shrink-0 flex justify-center font-semibold text-center text-xl `}
              >
                {langConfig.config["ar"].alert}
              </h1>
              <div
                className={`font-[roman] rtl:font-[Droid] text-xl leading-[40px] sm:leading-[50px] text-center w-full`}
              >
                سوف تُمحَى جميع إجاباتك المحفوظة
              </div>
              <div className="shrink-0 h-10 flex flex-row justify-between gap-5">
                <button
                  type="button"
                  onClick={() => clearFunction()}
                  className={`text-white border bg-primary border-primary select-none text-center py-2 w-full rounded-full m-auto`}
                >
                  استمرار
                </button>
                <button
                  type="button"
                  onClick={() => setModalAlert(false)}
                  className={`text-white border bg-primary border-primary select-none text-center py-2 w-full rounded-full m-auto} rounded-full m-auto`}
                >
                  رجوع
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
