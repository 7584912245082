import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
 import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  Subjects,
  Exam,
  NotFound,
  TermsAndConditions,
  PrivacyPolicy,
  Lessons,
} from "./pages";
import FirebaseToast from "./components/firebase/FirebaseToast";
import Login from "./pages/Login";
import { ProtectedLayout } from "./layouts/protectedLayout";
import Review from "./pages/Review";
import ReviewnotificationQuestions from "./pages/ReviewnotificationQuestions";
import QuestionDetails from "./pages/QuestionDetails";

const root = ReactDOM.createRoot(document.getElementById("root"));

const handleCallback = (message) => {};

root.render(
  <Provider store={store}>
      <FirebaseToast callback={handleCallback} />

      <BrowserRouter>
        <App />
        <Routes>
          <Route path="/Login" element={<Login />} />
          <Route element={<ProtectedLayout />}>
            <Route exact path="/" element={<App />} />
            <Route path="/home" element={<Home />} />
            <Route path="/subjects/:school/:division" element={<Subjects />} />
            <Route path="/lessons/:school/:subjectID" element={<Lessons />} />
            <Route path="/Review" element={<Review />} />
            <Route 
              path="/ReviewnotificationQuestions/:id"
              element={<ReviewnotificationQuestions />}
            />
            <Route path="/exam/:subjectID/:id" element={<Exam />} />
            <Route path="/exam/:subjectID/:id/:index" element={<Exam />} />
            <Route
              path="/question_details/:question_id"
              element={<QuestionDetails />}
            />
            <Route path="/terms&conditions" element={<TermsAndConditions />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          </Route>
          <Route path="/exam/notfound" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
          <Route component={NotFound} />
        </Routes>
      </BrowserRouter>
  </Provider>
);

 