import React, { useRef, useEffect, useState } from "react";

const RectangleComponent = ({
  dir,
  QuesID,
  data,
  coordinates,
  onMessageChange,
  refresh,
}) => {
  const rectRefs = useRef([]);
  const [inputValue, setInputValue] = useState("");
  const handleButtonClick = () => {
    onMessageChange(inputValue);
  };
  const height = window.innerHeight;
  const width = window.innerWidth;
  useEffect(() => {
    const saveRectanglesPositions = () => {
      const positions = rectRefs.current
        .map((rect, index) => {
          const THErect = rect?.children[1]?.getBoundingClientRect();
          // console.log(`Width: ${THErect.width}, Height: ${THErect.height}`);

          if (rect) {
            const rectBounds = rect?.getBoundingClientRect();

            return {
              box: {
                x: rectBounds.x - coordinates.x,
                y: rectBounds.y - coordinates.y,
                height: rectBounds.height - 4,
                width: rectBounds.width,
              },
              start: {
                x: THErect.x - coordinates.x + 5,
                y: THErect.y - coordinates.y + 5,
              },
              item: data[index],
              dir: dir,
            };
          }
          return null;
        })
        .filter(Boolean);

      localStorage.setItem(
        `${QuesID}-rectanglePositions-${dir}`,
        JSON.stringify(positions)
      );

      // console.log("Positions saved:", positions);
      setInputValue({ coordinates, height, width, positions });
      handleButtonClick();
    };

    saveRectanglesPositions();
  }, [coordinates, height, width, refresh]);

  function handleClick(e, item) {
    const rect = e?.target?.getBoundingClientRect();
//     console.log(`Item: ${item}`);
//     console.log(`X: ${rect.x}, Y: ${rect.y}`);
//     console.log(`Width: ${rect.width}, Height: ${rect.height}`);
//     console.log(`Other details: dir=${dir}`);
  }

  return (
    <div className="max-w-[35%]  ">
      {data?.map((item, i) => (
        <div
          key={i}
          style={{
            // width: "100px",
            border: "1px solid black",
          }}
          onClick={(e) => handleClick(e, item)}
          className={`rounded-lg flex justify-between   max-w-[95%]  ${
            dir === "start" && "flex-row-reverse"
          } items-center relative my-3  `}
          ref={(el) => (rectRefs.current[i] = el)}
        >
          <div className="grow text-center   ">
            <p
              className="text-sm sm:text-sm md:text-base s  xl:text-lg  "
              dangerouslySetInnerHTML={{ __html: item }}
            />
          </div>

          <div
            className={`  rounded-full h-3 w-3 hover:h-7  ${
              dir === "start" ? "-left-2" : " -right-2"
            } absolute `}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default RectangleComponent;
