import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    axios
      .post(
        "https://emmobank.com/api/login",
        {
          email: email,
          password: password,
          fcm_token: localStorage.getItem("fcm_token"),
        },
        {
          headers: {
            "access-token": "qwertasdfg123@+1",
            "Content-Type": "multipart/form-data"
          },
        }
      )
      .then((response) => {
        const { status, data } = response.data;
        if (status === 1) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("userData", JSON.stringify(data.user));
          const pathBefore = localStorage.getItem("beforeLogin")
          navigate('/home');
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen min-w-screen bg-[#BFBFBF] pt-5 ">
      <form onSubmit={handleSubmit} className="flex flex-col gap-2 bg-[#ffffff] p-8  rounded-3xl shadow-2xl w-[400px]  max-w-[85vw]">
        <img
          className="h-auto m-auto w-[150px] pb-10"
          alt="GPS education"
          src="./bg/GPS-Logo.svg"
        />
        <div className="mb-4">
           
          <label htmlFor="email" className="text-blue-950 block font-bold mb-2">Email</label>
          <input
            type="email"
            id="email"
            placeholder="me@mail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="appearance-none bg-white rounded-lg border w-full py-2 px-3 text-gray-700 leading-tight"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="text-blue-950 block font-bold mb-2">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="appearance-none bg-white rounded-lg border w-full py-2 px-3 text-gray-700 leading-tight"
            required
          />
        </div>
        <div className="flex justify-center pt-10 pb-2">
          <button
            type="submit"
            className="bg-primary hover:bg-blue-700 w-44 text-white font-bold py-2 px-4 rounded-full"
          >
            {loading ? "Loading..." : "Login"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;
