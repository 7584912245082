export const books = [
    {
        id: 1,
        name: 'Math',
        image: '/bg/icons/algebraAndGeometry.svg',
        division: 'literary',
        school: 'all',
        description: '',
        sort:11,
        date:'',
        active: true,
        grade: "6primary"
    }
]