import React, { useEffect, useRef } from "react";
import DisablePullToRefresh from "./PullToRefreshProvider";
import ChooseInLine from "../chooseInLinePackage/ChooseInLine";
import Order from "../orderPackage/Order";
import { Blanks } from "../blanksPackge/Blanks";
import Table from "../tablePackage/Table";
import Diagram from "../diagramPackage/Diagram";
import InteractiveBoxes from "../connectPackage/InteractiveBoxes";
import { RightWrong } from "../rightWrongPackge/RightWrong";
import { MAQ } from "../maqPackge/MAQ";
import { MCQ } from "../mcqPackage/MCQ";
import { Correct } from "./../Correct";
import { Essay } from "./../essayPackge/Essay";
import { QuesHead } from "../QuesHead";
import { useDispatch, useSelector } from "react-redux";
import TheCorrectAnswer from "../../ReviewCompo/TheCorrectAnswer";
import { setHeight } from "../../../store/actions";

const TheRenderQuesBody = ({ question }) => {
  const { currentQues, lang } = useSelector((state) => state.userAppReducer);
  {
    localStorage.getItem("1") == "1" && console.log(question?.QuesType);
  }
  {
    localStorage.getItem("1") == "1" && console.log(question);
  }
  const dispatch = useDispatch();
  // console.log(question);
  const renderComponent = () => {
    switch (question?.QuesType) {
      case "mcq":
        return <MCQ question={question} />;
        case "maq":
        return <MAQ question={question} />;
      case "correct":
        return <Correct question={question} />;
      case "essay":
        return <Essay options={{ fullReport: true }} question={question} />;
      case "diagram":
        return <Diagram question={question} />;
      case "blank":
        return <Blanks question={question} />;
      case "table":
        return <Table question={question} />;
      case "order":
        return <Order options={{ fullReport: true }} question={question} />;
      case "chooseInLine":
        return (
          <ChooseInLine options={{ fullReport: true }} question={question} />
        );
      case "rightWrong":
        return <RightWrong question={question} />;
      case "connect":
        return (
          <DisablePullToRefresh>
            <InteractiveBoxes
              options={{ fullReport: true }}
              question={question}
            />
          </DisablePullToRefresh>
        );
      default:
        return <></>;
    }
  };

  const divRef = useRef(null);
  const { setShowToolValue } = useSelector((state) => state.userAppReducer);

  useEffect(() => {
     if (divRef.current) {
      //  console.log('ارتفاع العنصر هو:', divRef.current.offsetHeight);
    }
    dispatch(setHeight(divRef.current.offsetHeight))
  }, [question ,setShowToolValue ]);

  return (
    <div  ref={divRef} className="w-full bg-white">
      <div dir={!question?.language_code&&lang==="ar"?"rtl" : question?.language_code&&question?.language_code==="ar"?"rtl":"ltr"}>

      <QuesHead
        number={currentQues + 1}
        head={question?.QuesHeadTxt}
        // quesTxt={question.QuesTxt }
      />

      {question?.paragraphText && (
        <div className="p-4  bg-gray-100 border-3 border-b-4 border-green-500">
          <div className="flex-1">
            <div
              dangerouslySetInnerHTML={{ __html: question?.paragraphText }}
            />
          </div>
        </div>
      )}
        
      {renderComponent()}



      <TheCorrectAnswer data={question} />
      </div>
    </div>
  );
};

export default TheRenderQuesBody;
