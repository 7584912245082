export const SET_START_EXAM = {}
export const SET_LANG = {}
export const SET_BLUR = {}
export const SET_CURRENT_QUES= {}
export const EXAM_COUNTER= {}
export const SET_ACTUAL_NUMBER= {}
export const SET_QUESTIONS_LIST = {}
export const SET_USER_ANSWER = {}
export const SET_BRIEF_REPORT = {}
export const SET_FULL_REPORT = {}
export const SET_MODAL= {}
export const SET_MODAL_TITLE = {}
export const SET_MODAL_SUB_TITLE = {}
export const SET_MODAL_BODY = {}
export const SET_MODAL_BUTTON = {}
export const SET_ESSAY_MODAL= {}
export const SET_ESSAY_MODAL_TITLE = {}
export const SET_ESSAY_MODAL_BODY = {}
export const SET_ESSAY_MODAL_USER = {}
export const SET_ESSAY_MODAL_BUTTON = {}
export const SET_BOOKMARK = {}
export const SET_ESSAY_SCORE = {}
export const SET_TIMER = {}
export const SET_EXAM_CONFIG = {}
export const SET_USER_LOGIN = {}
export const SET_SCREEN = {}
export const SET_SHOW_SCREEN = {}
export const SET_Canvas_Clear = {}
export const SET_Canvas_Undo = {}
export const SET_SHOW_TOOL = {}
export const SET_Height_TOOL = {}

export const setStartExam = startExam => dispatch => {
    dispatch({
        type: SET_START_EXAM,
        payload: startExam,
    })
}
export const setLang= lang => dispatch => {
    dispatch({
        type: SET_LANG,
        payload: lang,
    })
}
export const setBlur= blure => dispatch => {
    dispatch({
        type: SET_BLUR,
        payload: blure,
    })
}
export const setModal = setModal => dispatch => {
    dispatch({
        type: SET_MODAL,
        payload: setModal,
    })
}
export const setCurrentQues = setCurrent => dispatch => {
    dispatch({
        type: SET_CURRENT_QUES,
        payload: setCurrent,
    })
}
export const setExamCounter = examCounter => dispatch => {
    dispatch({
        type: EXAM_COUNTER,
        payload: examCounter,
    })
}
export const setQuestionsList = questions => dispatch => {
    dispatch({
        type: SET_QUESTIONS_LIST,
        payload: questions,
    })
}

export const setUserAnswer = userAnswer => dispatch => {
    dispatch({
        type: SET_USER_ANSWER,
        payload: userAnswer,
    })
}
export const setBriefReport = briefReport => dispatch => {
    dispatch({
        type: SET_BRIEF_REPORT,
        payload: briefReport,
    })
}
export const setFullReport = fullReport => dispatch => {
    dispatch({
        type: SET_FULL_REPORT,
        payload: fullReport,
    })
}
export const setModalTitle = modalTitle => dispatch => {
    dispatch({
        type: SET_MODAL_TITLE,
        payload: modalTitle,
    })
}
export const setModalSubTitle = modalSubTitle => dispatch => {
    dispatch({
        type: SET_MODAL_SUB_TITLE,
        payload: modalSubTitle,
    })
}
export const setModalBody = modalBody => dispatch => {
    dispatch({
        type: SET_MODAL_BODY,
        payload: modalBody,
    })
}
export const setModalBotton = modalButton => dispatch => {
    dispatch({
        type: SET_MODAL_BUTTON,
        payload: modalButton,
    })
}
export const setBookmark = bookmark => dispatch => {
    dispatch({
        type: SET_BOOKMARK,
        payload: bookmark,
    })
}
export const setEssayModal = essay => dispatch => {
    dispatch({
        type: SET_ESSAY_MODAL,
        payload: essay,
    })
}
export const setEssayModalTitle = essay => dispatch => {
    dispatch({
        type: SET_ESSAY_MODAL_TITLE,
        payload: essay,
    })
}
export const setEssayModalBody = essay => dispatch => {
    dispatch({
        type: SET_ESSAY_MODAL_BODY,
        payload: essay,
    })
}
export const setEssayModalUser = essay => dispatch => {
    dispatch({
        type: SET_ESSAY_MODAL_USER,
        payload: essay,
    })
}
export const setEssayModalButton = essay => dispatch => {
    dispatch({
        type: SET_ESSAY_MODAL_BUTTON,
        payload: essay,
    })
}
export const setEssayScore = essay => dispatch => {
    dispatch({
        type: SET_ESSAY_SCORE,
        payload: essay,
    })
}
export const setTimer = timer => dispatch => {
    dispatch({
        type: SET_TIMER,
        payload: timer,
    })
}
export const setScreen = status => dispatch => {
//     console.log("💎:) -- in redux SET_SCREEN is CALL");
     dispatch({
        type: SET_SCREEN,
        payload: status,
    })
}
export const showScreenModal = status => dispatch => {
//     console.log("showScreenModal 📸📷  ");
    dispatch({
        type: SET_SHOW_SCREEN,
        payload: status,
    })
}
export const setCanvasClear = status => dispatch => {
    //     console.log("SET_Canvas_Clear 📸📷  ");
        dispatch({
            type: SET_Canvas_Clear,
            payload: status,
        })
        
        
    }
export const setCanvasUndo = status => dispatch => {
        console.log("setCanvasUndo 📸📷  ");
        dispatch({
            type: SET_Canvas_Undo,
            payload: status,
        })

        
    }
export const setExamConfig = config => dispatch => {
    dispatch({
        type: SET_EXAM_CONFIG,
        payload: config,
    })
}
export const setUserLogin = status => dispatch => {
    dispatch({
        type: SET_USER_LOGIN,
        payload: status,
    })
}
export const setActualNumber = status => dispatch => {
    dispatch({
        type: SET_ACTUAL_NUMBER,
        payload: status,
    })
}
export const setShowTool = status => dispatch => {
    dispatch({
        type: SET_SHOW_TOOL,
        payload: status,
    })
}
export const setHeight = status => dispatch => {
    dispatch({
        type: SET_Height_TOOL,
        payload: status,
    })
}
