import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { localStorageAnswered } from "../AglopalQuestionMethod/localStorageAnswered";

const parseTextWithButtons = (text) => {
  const regex = /##(.*?)##/g;
  const parts = [];
  let lastIndex = 0;
  text.replace(regex, (match, p1, offset) => {
    if (offset > lastIndex) {
      parts.push({ type: "text", content: text.slice(lastIndex, offset) });
    }

    const options = p1.split("},{").map((option) => {
      const regex = /<span\s+order\s*=\s*"(\d+)"\s*>(.*?)<\/span>/;

      const match = option.match(regex);

      if (match) {
        const orderValue = match[1];

        const spanText = match[2];

        return { item: spanText, order: orderValue };
      } else {
      }

    });

    parts.push({ type: "buttons", options });

    lastIndex = offset + match.length;
  });

  if (lastIndex < text.length) {
    parts.push({ type: "text", content: text.slice(lastIndex) });
  }


  return parts;
};

const ChooseInLine = ({ question,options }) => {
  const [userAnswer, setUserAnswer] = useState(
    JSON.parse(localStorage.getItem(question?.QuesID)) || []
  );
  const {  currentQues } = useSelector(
    (state) => state.userAppReducer
  );
  
  
  const briefReport=false
  const parts = parseTextWithButtons(question.QuesTxt);

  const handleClick = useCallback(
    (option) => {
      if (briefReport) return;


      const ansFromLocal =
        JSON.parse(localStorage.getItem(question?.QuesID)) || [];

      const existingIndex = ansFromLocal.findIndex(
        (item) => item.order === option.order
      );

      if (existingIndex !== -1) {
        ansFromLocal[existingIndex] = option
      } else {
        ansFromLocal.push(option);
      }
      setUserAnswer(ansFromLocal);
      localStorage.setItem(question?.QuesID, JSON.stringify(ansFromLocal));
      localStorageAnswered(question.id);
    },
    [question?.QuesID]
  );

  function getClass(option) {


 

   

    const foundItem = question?.QuesRightAns?.find(item => item?.order == option?.order);

    const normalizedOption = normalizeText(option?.item);
    const normalizedRightAns = normalizeText(foundItem?.item);
 
     
return normalizedOption === normalizedRightAns
        ? "bg-primary text-white font-semibold"
        : "border-black text-primary font-semibold";

        
  }

  return (
    <div>
      <div className="p-3     relative flex flex-wrap items-baseline    ">

        {parts.map((part, index) => {

          if (part.type === "text") {
            return <p key={index} dangerouslySetInnerHTML={{ __html: part.content }} />;
          }

          if (part.type === "buttons") {
            return (
              <span key={index} className="space-y-2 ">
                {part.options.map((option, idx) => (
                  <button
                  disabled
 
                    key={idx}
                    className={` m-1  ${getClass(
                      option
                    )} px-3 py-1 rounded-lg `}
                    onClick={() => handleClick(option)}
                    style={{ border: "1px solid #aaaaaa" }}
                  >
                    {option?.item}   
                  </button>
                ))}
              </span>
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};

export default ChooseInLine;

const normalizeText = (text) => {
  const trimmedText = text?.trim();

  const reducedSpacesText = trimmedText?.replace(/\s+/g, " "); 

  const lowerCasedText = reducedSpacesText?.toLowerCase();

  return lowerCasedText;
};

