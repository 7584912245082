import React from "react";
import { BackNavBar } from "../BackNavBar";

export default function NoQuestions({text="No questions in This exam",children }) {
  return (
    <div>
    <BackNavBar/>
    {children }
      <div className="  flex justify-center items-center ">
        <h1 className="mt-40 text-3xl text-red-800">{text}</h1>
      </div>
      
    </div>
  );
}
