import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpellingPractice from "./SpellingPractice";
import configLang from "../../sql/config.json";
import { localStorageAnswered } from "../../utils/localStorageAnswered";
 
export default function CorrectRender2({
  index,
  Correct,
  uuid,
  answer,
  question,
}) {
  const [localAnswer, setLocalAnswer] = useState(null);
  const [answers, setAnswers] = useState([]);
  const dispatch = useDispatch();
  const { lang, briefReport, currentQues } = useSelector(
    (state) => state.userAppReducer
  );
  const {config} = configLang

  console.log("-====");
  console.log(lang);
  console.log(briefReport);
  console.log(currentQues);
  const numeric = {
    ar: ["ا", "ب", "جـ", "د", "هـ"],
    en: ["a", "b", "c", "d", "e"],
  };

  useEffect(() => {
    getUserAnswer(question.id);
  }, [question.id, currentQues]);

  const getUserAnswer = async (id) => {
    let my = JSON.parse(localStorage.getItem(id));
    setLocalAnswer(my);
//     console.log(my?.answers);
    setAnswers(my?.answers || []);
  };

  const arrayOfBooleans = answer.map((value) => value === "true");

  const registerAnswer = async (uuid, index, e) => {
    const value = e.target.value.toLowerCase();
//     console.log(answers);

    // console.log(index);
    // console.log(value);
    let my = JSON.parse(localStorage.getItem(uuid))?.answers || [];

    let updatedAnswers = [...my];
//     console.log(updatedAnswers);
    updatedAnswers[index] = value;
//     console.log(updatedAnswers);
    setAnswers(updatedAnswers);
    // console.log(question.QuesRightAns);

    localStorage.setItem(
      uuid,
      JSON.stringify({
        id: uuid,
        uuid: uuid,
        answers: updatedAnswers,
        right: question?.QuesRightAns?.map((word) => word.toLowerCase()),
      })
    );

    localStorageAnswered(question.id)
   };

  if (briefReport) {
    if (localAnswer?.answers[index] == null) {
      return (
        <>
          <span className="flex row w-full gap-2 h-full    justify-center items-center">
            {/* <span className={` rtl:-pt-[4px] `}>{numeric[lang][index]}</span> */}
            {/* <span>{answer[index].toLowerCase()}</span> */}
            <span className={` rtl:-pt-[4px] `}>{numeric[lang][index]}</span>

            <span
              className={` px-5 border-2 border-red-300      rounded-lg     p-1  w-full flex flex-row  justify-between`}
            >
              <span>
 
                <input
                  className="text-green-600 font-bold bg-black "
                  type="text"
                  value={answer[index].toLowerCase()}
                />
              </span>

              <span className="  l flex flex-col justify-center items-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6 text-red-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </span>
          </span>
        </>
      );
    }
    if (
      localAnswer?.answers[index] != null &&
      arrayOfBooleans[index] != localAnswer?.answers[index]
    ) {
      return (
        <>
          <span className="flex row w-full gap-2 h-full    justify-center items-center">
            <span className={` rtl:-pt-[4px] `}>{numeric[lang][index]}</span>

            <SpellingPractice
              targetWord={answer[index].toLowerCase()}
              inputWord={answers[index]}
            />
          </span>
        </>
      );
    }
  } else {
    return (
  
        <input
          value={answers[index]}
          name="name"
          type="text"
          placeholder={config[lang].CorrectPlaceHolder}
          onChange={(e) => {
            registerAnswer(uuid, index, e);
          }}
          className=" resize-none h-fit flex w-full p-1 border-3 rounded-lg shadow-md  border-dashed " 
        />
     );
  }
}
