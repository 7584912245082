import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

const CustomDropdown = ({ tree, examLang, handleSelection, className }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close the dropdown
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onSelect = (value) => {
    handleSelection(value?.id);
    setSelectedValue(value);
    setIsOpen(false);
  };

  useEffect(()=>{
    setSelectedValue(null)
  },[tree])

  const renderTree = (nodes, level = 0) => {
    return nodes.map((node) => (
      <div
        key={node.id}
        className={`py-2 cursor-pointer grid-cols-1 divide-y ${
          selectedValue?.id === node.id ? "bg-gray-200" : ""
        }`}
        style={{
          direction: examLang === "ar" ? "rtl" : "ltr",
        }}
        
      >
        <div className="w-full px-1 divid-y" onClick={()=>{onSelect(node)}}>
        {level ? <span>
             <svg xmlns="http://www.w3.org/2000/svg" className="h-5 inline-block"  viewBox={`0 -${960} ${960 } ${960}`} fill="#5f6368"><path d={`M400-240 160-480l240-240 56 58-142 142h${486 * level}v80H314l142 142-56 58Z`}/></svg></span> : <></>}
          {node.name}
        </div>

        {node?.childs?.length > 0 && renderTree(node.childs, level + 1)}
      </div>
    ));
  };

  return (
    <div className={`${className} relative`} ref={dropdownRef}>
      <div
        className="max-h-[30px] max-w-full p-1 border cursor-pointer rounded-md flex justify-between items-start overflow-hidden"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="my-auto">{selectedValue?.name || "اختر الامتحان ..."}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={` h-3 transition-transform ${isOpen ? 'rotate-180' : 'rotate-0'} my-auto`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
      </svg>      
    </div>
      {isOpen && (
        <div className="absolute border w-full max-h-60 overflow-auto bg-white" >
          {renderTree(tree)}
        </div>
      )}
    </div>
  );
};

export default function ReviewRow({
  quData,
  imgae,
  idOfQuestion,
  onFiltersChange,
}) {
  const [loading, setloading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalRef = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedSemester, setSelectedSemester] = useState(""); // الفصل الدراسي
  const [statuses, setStatuses] = useState([]);
  const [semesters, setSemesters] = useState([]); // الفصول الدراسية المتاحة

  const [selectedEducationalLevel, setSelectedEducationalLevel] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [examId, setExamId] = useState("");

  const [educationalLevels, setEducationalLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [exams, setExams] = useState([]);
  const [examLang, setExamLang] = useState([]);
  const [notes, setNotes] = useState("");

  function sendItsDone(id) {
    setloading(true);
    const token = localStorage.getItem("token");

    const headers = {
      "access-token": "qwertasdfg123@+1",
      Authorization: `Bearer ${token}`,
    };

    const dataToSend = {
      id: quData?.question?.QuesID || quData?.question_id,
      status: 2,
      note: notes,
    };

    const serverURL = "https://emmobank.com/api/change_status";

    axios
      .post(serverURL, dataToSend, { headers })
      .then((response) => {
        setloading(false);
        setNotes("");
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setloading(false);
      });
  }

  // console.log(quData?.question?.QuesID,quData?.question_id)

  useEffect(() => {
    const fetchData = async () => {
      const mytoken = localStorage.getItem("token");
      const headers = {
        "access-token": "qwertasdfg123@+1",
        Authorization: `Bearer ${mytoken}`,
      };

      try {
        const response = await axios.get(
          "https://emmobank.com/api/filter_data",
          { headers }
        );
        setStatuses(response.data.teacher_question_statuses);
        setSemesters(response.data.semesters);
        setEducationalLevels(response.data.educational_levels);
        setGrades(response.data.grades);
        setSubjects(response.data.subjects);
      } catch (error) {
        console.error("Error fetching filter data:", error);
      }
    };

    fetchData();
  }, []);

  const getExamList = async (subjectId, gradeId) => {
    const mytoken = localStorage.getItem("token");
    const headers = {
      "access-token": "qwertasdfg123@+1",
      Authorization: `Bearer ${mytoken}`,
    };
    await axios
      .get(
        `https://emmobank.com/api/get_exam_list?subject_id=${subjectId}&grade_id=${gradeId}`,
        { headers }
      )
      .then(async (respons) => {
        // setLessonsList(respons.data.exams);
        setExams(respons.data.exams);
        setExamLang(respons.data?.lang);
      })
      .catch(() => {})
      .finally(() => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedGrade && selectedSubject) {
      getExamList(selectedSubject, selectedGrade);
    } else {
      setExams([]);
    }
  }, [selectedGrade, selectedSubject]);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleSemesterChange = (event) => {
    setSelectedSemester(event.target.value);
  };

  const handleSearch = () => {
    onFiltersChange({
      status: selectedStatus,
      semester_id: selectedSemester,
      // educational_level_id: selectedEducationalLevel,
      grade_id: selectedGrade,
      subject_id: selectedSubject,
      exam_id: examId,
    });
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleExamTree = (tree, level = 0) => {
    return tree?.map((node) => (
      <React.Fragment key={node.id}>
        <option
          dir={`${examLang === "ar" ? "rtl" : "ltr"}`}
          value={node.id}
          style={{ paddingLeft: `${level * 10}px` }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -${
                960 * level
              } ${960 * level} ${
                960 * level
              }" width="24px" fill="#5f6368"><path d="M400-240 160-480l240-240 56 58-142 142h${
                486 * level
              }}v80H314l142 142-56 58Z"/></svg>`,
            }}
          />
          {node.name}
        </option>
        {node?.childs?.length > 0 && handleExamTree(node.childs, level + 1)}
      </React.Fragment>
    ));
  };
  return (
    <div className="z-50 font-['Almarai'] flex justify-center bg-white border border-1 p-1 border-indigo-600 ">
      <div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="border  font-['Almarai'] rounded-lg p-2 bg-primaryFont text-white"
        >
          تم الموافقة عليه
        </button>
      </div>

      <div
        dir="rtl"
        className="grid grid-cols-2 md:grid-cols-4 gap-2 bg-white justify-start "
      >
        <select
          className="order-4 border rounded-lg px-2 py-1  font-['Almarai']"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <option value="">اختر الحالة...</option>
          {statuses.map((status) => (
            <option key={status.id} value={status.id}>
              {status.name_ar}
            </option>
          ))}
        </select>
        <select
          className="order-3 border rounded-lg px-2 py-1  font-['Almarai']"
          value={selectedSemester}
          onChange={handleSemesterChange}
        >
          <option value="">اختر الفصل الدراسي...</option>
          {semesters.map((semester) => (
            <option key={semester.id} value={semester.id}>
              الفصل {semester.name}
            </option>
          ))}
        </select>
        {/* <select
            className="border rounded-lg px-2 py-1  font-['Almarai']"
            value={selectedEducationalLevel}
            onChange={(e) => setSelectedEducationalLevel(e.target.value)}
          >
            <option value="">اختر المرحلة التعليمية...</option>
            {educationalLevels.map((level) => (
              <option key={level.id} value={level.id}>
                {level.name}
              </option>
            ))}
          </select> */}

        <select
          className="order-2 border rounded-lg px-2 py-1  font-['Almarai']"
          value={selectedGrade}
          onChange={(e) => setSelectedGrade(e.target.value)}
        >
          <option value="">اختر الصف...</option>
          {grades.map((grade) => (
            <option key={grade.id} value={grade.id}>
              {grade.name}
            </option>
          ))}
        </select>

        <select
          className="order-1 border rounded-lg px-2 py-1  font-['Almarai']"
          value={selectedSubject}
          onChange={(e) => setSelectedSubject(e.target.value)}
        >
          <option value="">اختر المادة...</option>
          {subjects.map((subject) => (
            <option key={subject.id} value={subject.id}>
              {subject.name}
            </option>
          ))}
        </select>
        <CustomDropdown
        className="order-5 col  font-['Almarai']"
          examLang={examLang}
          tree={exams}
          handleSelection={(examId) => {
            setExamId(examId);
          }}
        />
        {/* <select
            className="order-5 border rounded-lg px-2 py-1  font-['Almarai']"
            value={examId}
            onChange={(e) => CustomDropdown(e.target.value)}
          >
            <option value="">اختر الامتحان...</option>
            {
              handleExamTree(exams)
            }
             {exams.map((exam) => (
              <option key={exam.id} value={exam.id}>
                {exam.name}
              </option>
            ))} 
          </select> */}
        {/* <input
            type="text"
            value={examId}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
              setExamId(numericValue);
            }}
            className="order-5 border rounded-lg px-2 font-['Almarai'] placeholder-black placeholder:py-1"
            placeholder="اكتب رقم الامتحان ..."
          /> */}
        <button
          className="order-last border rounded-lg flex  font-['Almarai'] flex-row gap-3 items-center justify-center px-2 bg-primary text-white"
          onClick={handleSearch}
        >
          بحث
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>
        </button>
      </div>
      {/* Modal */}
      {isModalOpen && (
        <div
          ref={modalRef}
          className=" fixed inset-0 flex items-center justify-center z-[100] bg-black bg-opacity-50"
          dir="rtl"
        >
          <div className="w-[80%] max-w-[500px] bg-white p-5 rounded-lg shadow-lg">
            <label
              htmlFor="notes"
              className="block text-md font-bold text-gray-700 mb-2"
            >
              الملاحظات:{" "}
            </label>
            <textarea
              id="notes"
              className="w-full p-2 border border-gray-300 rounded"
              rows="4"
              placeholder="اكتب ملاحظاتك هنا..."
              value={notes}
              onChange={handleNotesChange}
            />
            <div className="flex flex-row justify-center gap-5">
              <button
                className="bg-primary text-white py-2 px-4 rounded "
                onClick={() => sendItsDone(2)} // Close the modal
              >
                {!loading ? "إرسال" : "جارى ارسال الطلب.. ..."}
              </button>
              <button
                className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
                onClick={() => {
                  setIsModalOpen(false);
                  setNotes("");
                }} // Close the modal
              >
                إغلاق
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
