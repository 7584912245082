import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import configLang from "../../../sql/config.json";
import { localStorageAnswered } from "../AglopalQuestionMethod/localStorageAnswered";
import { ResizeImage } from './../../resizeImage';
import EssayAnswerModal from "./essayAnswerModal";
 
export const Essay = (props) => {
  const { config } = configLang;
  const { question, multipart ,options} = props;
  const [myAnswer, setMyAnswer] = useState(null);
  const { lang, briefReport, currentQues } = useSelector(
    (state) => state.userAppReducer
  );

  
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getUserAnswer(question.id);
  }, [question.id, currentQues]);
  const getUserAnswer = async (id) => {
    setLoading(false);
    let my = JSON.parse(localStorage.getItem(id));
    setMyAnswer(my);
    setLoading(false);
  };

  const registerAnswer = async (uuid, answer, multipart) => {
    localStorage.setItem(
      uuid,
      JSON.stringify({ id: uuid, uuid: uuid, answer, multipart: multipart })
    );
    localStorageAnswered(question.id)
 
    setMyAnswer({ id: uuid, uuid: uuid, answer, multipart: multipart });
  };

  return (
    <>
      <div className="  rounded-lg p-1 flex-col flex gap-2 px-2">
        {loading ? (
          <>
            <div className="bg-slate-100 flex w-full h-full top-0 left-0 z-50 absolute rounded-lg flex-col justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10 animate-spin"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </div>
          </>
        ) : (
          <></>
        )}
        <ResizeImage size="rem" type="ques" text={question.QuesTxt} />
        <div className="flex flex-col gap-1 justify-start items-start pb-2">

           
          {briefReport||true ? (
            <>
              <div
                className={`resize-none h-auto flex w-full p-1 border rounded-lg shadow-md my-2 bg-white min-h-[120px]`}
              >
                {/* {myAnswer?.answer} */}
                <ResizeImage size="rem" text={question.QuesShowAns1.txt} />

              </div>
              {false? (
                <>
                  <EssayAnswerModal
                     modelAnswer={question.QuesShowAns1.txt}
                   />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <textarea
              autoFocus
              value={myAnswer?.answer}
              name="userAnswer"
              id="userAnswer"
              onChange={(e) =>
                registerAnswer(question.id, e.target.value, multipart)
              }
              disabled={options.fullReport}
              rows={6}
              className={`resize-none h-fit flex w-full p-1 border rounded-lg   my-2`}
              placeholder={config[lang].EssayPlaceHolder}
            />
          )}
        </div>
      </div>
    </>
  );
};
