import React from 'react';
import { NavBar } from '../components';
import langConfig from "../sql/config.json"
import conf from "../../package.json"

export const TermsAndConditions = ({ nav, close }) => {

    return (
        <div dir="rtl" className="flex flex-col justify-start h-full w-full p-0 m-0 md:max-w-[875px] md:m-auto overflow-hidden">
            {
                (nav === false) ? (
                    <div>
                        <div
                            className={`flex w-full sticky top-0 px-4 py-3 bg-primary flex-col justify-between  gap-3 rounded-b-lg z-50 font-[Almarai]`}
                        >
                            <div className="flex flex-row-reverse justify-between w-full">
                                <div className="flex w-fit">
                                </div>
                                <div
                                    className={`flex w-full justify-center text-white font-[Almarai] font-bold md:text-xl relative`}
                                >
                                    {langConfig.config['ar'].TermsAndConditions}
                                </div>
                                <div className={`flex w-fit flex-row gap-3`}>
                                    <button
                                        onClick={() => close()}
                                        className="capitalize flex flex-row gap-2 text-white"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className={`w-6 h-6 rotate-180`}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <NavBar switchLang={false} menu={true} qr={false} back={false} title={true} titleText={
                        langConfig.config['ar'].TermsAndConditions
                    } />
                )
            }
            <div className="flex flex-col gap-3 w-full h-fit px-4 py-3 font-[Almarai] overflow-auto">
                <div className="indent-3 mt-3">أهلًا بك في موقع GPS الإلكتروني. نرجو منك قراءة شروط وأحكام استخدام موقعنا الإلكتروني لتتعرف على حقوقك القانونية والقواعد التي عليك اتباعها للاستفادة من خدماتنا والمحتوى المقدم على موقعنا الإلكتروني. مع العلم أنه باستخدامك لموقعنا فإنك توافق على الشروط والأحكام جنبًا إلى جنب مع سياسة الخصوصية دون قيد أو شرط.</div>
                <div className="indent-3">تقرأ في السطور التالية مجموعة من البنود بها تعريفات الشركة والأحكام العامة التي ستخضع لها عند استخدامك موقعنا ووصف لخدماتنا مع بند خاص بمعلوماتك الشخصية واستخداماتها بالإضافة إلى حقوق النشر ومسئوليات الشركة تجاهك.</div>


                {/* section */}
                <div className="text-xl font-bold mt-4">البند الأول: التعريفات  </div>
                <div className="indent-3">الشركة: يقُصد بها شركة GPS للطبع والنشر والتوزيع المالكة لمجموعة من سلاسل الكتب التعليمية في السوق المصري مثل المعاصر والامتحان بالإضافة إلى مجموعة من التطبيقات الإلكترونية للتعليم الإلكتروني.</div>
                <div className="indent-3">المحتوى: يقصد بالمحتوى  جميع التصميمات والصور ومقاطع الفيديو والنصوص والشعارات والأعمال الفنية والجرافيك والإعلانات وجميع المحتويات التي تخضع لحقوق الملكية الفكرية وهي المواد التي يتم تحميلها على الموقع من قِبل الشركة وتكون متصلة بالخدمات بطريقة أو بأخرى. </div>
                <div className="indent-3">أنت: يقصد بها المستخدمين ممن يشاهدون المحتوى عبر موقعنا الإلكتروني أو تطبيقنا أو أي وسيلة أو آلية تكنولوجية لتوصيل المحتوى الذي تملكه أو تديره الشركة.</div>
                <div className="indent-3">شروط وأحكام: يقصد بها البنود المذكورة في هذه الصفحة والتي تنظم التعامل بين المستخدم والموقع.</div>

                {/* section */}
                <div className="text-xl font-bold mt-4">البند الثاني: </div>
                <div className="indent-3">تخضع GPS لمجموعة الشروط والأحكام التي تقرها القوانين المصرية لدور النشر والطبع والتوزيع ويعد استخدامك للموقع أو تسجيلك في إحدى البرامج والخدمات في الموقع بقراءتك وموافقتك على الشروط والأحكام التالية في صيغتها الحالية والتعديلات التي تتم عليها بين الحين والآخر. </div>

                {/* section */}
                <div className="text-xl font-bold mt-4">البند الثالث:</div>
                <div className="indent-3">يحق للشركة تعديل الشروط والأحكام للامتثال إلى أحكام القوانين المعمول بها لتحسين الخدمات المقدمة دون الحاجة للحصول على موافقتك أو إخطارك وأنك مسئول عن تصفح سياساتنا من وقت إلى آخر للتعرف على التغييرات.  </div>

                {/* section */}
                <div className="text-xl font-bold mt-4">البند الرابع: وصف الخدمة </div>
                <div className="indent-3">تقدم شركة GPS خدمات الطبع والنشر والتوزيع للقطاع التعليمي المصري في البرامج التعليمية المختلفة بالثلاث لغات هم العربية والإنجليزية والفرنسية ونتعهد دائمًا ببذل الجهد اللازم لتحديث المعلومات في الموقع والتطبيق التابع له مع احتفاظ الشركة بالحق في تغيير أو تعليق أو إجراء التحسينات أو إيقاف أي جزء من أجزاء خدماتها مؤ قتًا أو دائمًا.</div>

                {/* section */}
                <div className="text-xl font-bold mt-4">البند الخامس: سلوك المستخدم </div>
                    <div className="flex w-full flex-row gap-3 justify-start">
                        <div className="shrink-0 w-2 h-2 rounded-full bg-primary mt-3"></div>
                        <div>يجب عليك عدم إرسال أو تحميل أي محتوى على الموقع لنقل الفيروسات أو البرامج الضارة أو محاولة الإضرار بأنظمة GPS أو اختراقها عن عمد. </div>
                    </div>
                    <div className="flex w-full flex-row gap-3 justify-start">
                        <div className="shrink-0 w-2 h-2 rounded-full bg-primary mt-3"></div>
                        <div>يجب ألا تحاول الوصول إلى منصتنا بطريقة غير مصرح بها بما في ذلك الخوادم وقواعد البيانات والتطبيقات. </div>
                    </div>
                    <div className="flex w-full flex-row gap-3 justify-start">
                        <div className="shrink-0 w-2 h-2 rounded-full bg-primary mt-3"></div>
                        <div>يجب ألا تحاول تعطيل تقديم خدماتنا بأي وسيلة أخرى بما في ذلك هجمات قطع الخدمة أو الوسائل الأخرى. </div>
                    </div>
                    <div className="flex w-full flex-row gap-3 justify-start">
                        <div className="shrink-0 w-2 h-2 rounded-full bg-primary mt-3"></div>
                        <div>يحتفظ موقع GPS الإلكتروني بحق تعليق أو حذف حسابات المستخدِمين المخالفين للقواعد العامة لسلوك المستخدمين. </div>
                    </div>
                    <div className="flex w-full flex-row gap-3 justify-start">
                        <div className="shrink-0 w-2 h-2 rounded-full bg-primary mt-3"></div>
                        <div>نحتفظ بحق اتخاذ إجراء قانوني ضد أي مستخدم ينتهك هذه الشروط بموجب قانون إساءة استخدام الكمبيوتر لعام ١٩٩٠</div>
                    </div>



                {/* section */}
                <div className="text-xl font-bold mt-4">البند السادس: اتفاقيات المستخدم </div>
                <div className="indent-3">باستخدامك لموقعنا فإنك توافق على تلقي الرسائل الإلكترونية الضرورية وخاصةً الإشعارات الخاصة بالرسوم والمصروفات المطبقة والمعلومات الخاصة بالمعاملات وغيرها من الرسائل حول خدماتنا ويمكن إرسال هذه الرسائل من خلال البريد الإلكتروني أو الرسائل النصية SMS. </div>
                <div className="indent-3">كذلك توافق على أن حقوق الملكية الفكرية للمواد المقدمة على موقعنا تعود لشركة GPS للطبع والنشر والتوزيع وتوافق على سياسة الخصوصية الخاصة بنا والتي يمكنك الاطلاع عليها من خلال الصفحة الخاصة بسياسة الخصوصية على موقع GPS الإلكتروني. </div>


                {/* section */}
                <div className="text-xl font-bold mt-4">البند السابع: تحديد المسئولية </div>
                <div className="indent-3">الشركة غير مسئولة عن أي ضرر مباشر أو غير مباشر سواء كان أصلي أو تبعي أو متعلق بالوصول إلى الخدمة أو استخدامها أو عدم القدرة على الوصول إلي الخدمة أو استخدامها بصورة كلية أو جزئية بما في ذلك على سبيل المثال إنقطاع الخدمة أو الأضرار الناتجة عن فقد أو تلف البيانات أو عدم دقة البيانات كذلك قيمة استرداد البيانات أو استخدام خدمات بديلة أو أي مطالبة من الغير نتيجة أي أضرار قد تصيب أجهزة الحاسب الآلي أو البرامج أو الهاتف أو غيرها من الممتلكات وأنك توافق على أن العلاج الوحيد والحصري لأي مشاكل أو عدم رضا عن الخدمة هو التوقف عن استخدام الخدمة. </div>
                <div className="indent-3">وبالتالي فإنك توافق على أن الشركة ليس لديها أي التزام أو مسؤولية تنشأ عن أو تتعلق بمحتواها المتاح من خلال الموقع ولن تتحمل الشركة أو مسؤولوها أو مساهموها أو موظفوها أو وكلاؤها أو مديروها أو شركاتها الفرعية أو الشركات التابعة أو الخلف أو المتنازلون أو الموردون بأي حال من الأحوال المسؤولية عن أي
                فقدان في الاستخدام، فقدان البيانات، خسارة العمل أو خسارة الأرباح أو الضرر الذي يلحق بالأجهزة، إلى الحد الذي كان من الممكن أن تتجنب فيه مثل هذا الضرر باتباع نصائحنا لتطبيق التحديثات على الخدمات أو المحتوى أو إذا كان هذا الضرر ناتجًا عن عدم اتباعك لإرشادات التثبيت بشكل صحيح أو في وضع الحد الأدنى من متطلبات النظام التي نصحنا بها وعدم الأداء أو الأداء غير الملائم أو التأخير في الالتزامات الناشئة عن الاتفاقيات الناتجة عن القوة القاهرة أو أي سبب غير متوقع بشكل معقول أو خارج عن سيطرة الشركة المعقولة. ينطبق هذا القسم إلى أقصى حد يسمح به القانون المعمول به. قد يكون لديك حقوق بموجب القانون المعمول به في منطقتك القضائية والذي ينص على سبل الانتصاف بالإضافة إلى تلك المنصوص عليها أعلاه.
                </div>


                {/* section */}
                <div className="text-xl font-bold mt-4">البند الثامن: إنهاء الخدمة </div>
                <div className="indent-3">يحق للشركة إنهاء الخدمة على الفور في أي وقت ولأي سبب وبالتالي لن يكون لديك الحق في استخدامها وأنه يمكنك إنهاء حسابك في أي وقت باتباع التعليمات المتاحة من خلال التطبيق.  </div>
                <div className="indent-3">وجميع أحكام الشروط والأحكام تتعلق بحماية ونفاذ حقوق الملكية الفكرية للشركة  وإقراراتك وضماناتك والإصدارات والتعويضات وحدود المسئولية وأنواع الأضرار وملكية البيانات والمعلومات والقانون الحاكم والمحكمة المختصة والأحكام العامة تظل سارية بعد الإنهاء. </div>

                <a href={`mailto:exams@gpseducation.com?subject=(GPS Education Exams - v${conf.APP_VERSION} - ${localStorage.getItem('uuid')})`}  className="flex w-full text-blue-600 underline text-center justify-center items-center mt-10">للدعم والمساعدة</a>

                {/* section */}
                <div className="text-xl font-bold mt-4"></div>
                <div className="indent-3"></div>


            </div>
        </div>
    )

}
