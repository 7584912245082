import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import configLang from "../../sql/config.json";
 import CorrectRender from "./CorrectRender2";
import { ResizeImage } from "../resizeImage";

export const Correct = (props) => {
  const { question } = props;
 
  
  const [maqAnswer, setMaqAnswer] = useState([null, null, null, null, null]);
  const { lang, briefReport, currentQues } = useSelector(
    (state) => state.userAppReducer
  );
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(false);
  const [modelBody, setModelBody] = useState(null);
 
  const { config } = configLang;

  useEffect(() => {
    setLoading(false);
    setMaqAnswer([null, null, null, null, null]);
   }, [question.id, currentQues]);

  
 
 
 

  return (
    <>
      <div className="bg-slate-100 border border-gray-300 rounded-lg p-1 flex-col flex gap-2 px-2">
        {loading ? (
          <>
            <div className="bg-slate-100 flex w-full h-full top-0 left-0 z-50 absolute rounded-lg flex-col justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10 animate-spin"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </div>
          </>
        ) : (
          <></>
        )}
        <ResizeImage size="rem" type="question" text={question.QuesTxt} />
        <div className="w-full h-0 border-b" />
        <span className=" flex flex-row">
   
            {question.QuesRightAns.map((Correct, index) => (
           <span className=" ">
 
           <CorrectRender
              answer={question.QuesRightAns}
              key={index}
              uuid={question.id}
              Correct={Correct}
              index={index}
              question={question}
              />
            
              </span>
          ))}
        </span>

      
      </div>
      {model ? (
        <>
          <div className="absolute w-screen h-screen top-0 left-0 bg-black bg-opacity-50 z-[99999] flex flex-col justify-center items-center">
            <div
              dir={lang === "ar" ? "rtl" : "ltr"}
              className={`bg-white shadow-2xl border border-company-red p-3 rounded-2xl w-[500px] min-h-[10vh] max-w-[90vw] flex flex-col gap-2  ease-in-in duration-500 delay-100  opacity-1 mt-0 relative`}
            >
              <div className="absolute m-auto -top-7 fill-white self-center flex border border-transparent border-t-company-red border-r-company-red -rotate-45 rounded-full p-0 bg-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-14 h-14 stroke-orange-400 rotate-45"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </svg>
              </div>
              <div className="text-center text-lg py-8">
                <ResizeImage type="answer" size="rem" text={modelBody} />
              </div>
              <div className="h-10" />
              <div
                onClick={() => {
                  setModelBody(null);
                  setModel(false);
                }}
                className="absolute m-auto -bottom-7 fill-white self-center flex border border-transparent border-b-company-red border-l-company-red -rotate-45 rounded-full p-0 bg-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-14 h-14 stroke-company-red rotate-45"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
