import langConfig from "../sql/config.json"

export const divisions = [
    {
        id: 1,
        name: {
            ar: langConfig.config['ar'].literary,
            en: langConfig.config['en'].literary
        },
        param: "literary",
        type: 1,
    },
    {
        id: 2,
        name: {
            ar: langConfig.config['ar'].mathematics,
            en: langConfig.config['en'].mathematics,
        },
        param: "mathematics",
        type: 1
    },
    {
        id: 3,
        name: {
            ar: langConfig.config['ar'].science,
            en: langConfig.config['en'].science,
        },
        param: "science",
        type: 1
    },
]