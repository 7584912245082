import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { NavBar } from "../components";
import {  books } from "../data";
import { setBlur} from "../store/actions";
import axios from "axios";
import Accordion from "../components/lessons/Accordion";

export const Lessons = (props) => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState("ar");
  const dispatch = useDispatch();
  const [lessonsList, setLessonsList] = useState([]);

  const [currentDivision, setCurrentDivision] = useState(
    localStorage.getItem("division")
  );
  const [retake, setRetake] = useState([]);
  useEffect(() => {
    dispatch(setBlur(true));
    getLastUserScore();
    checkAccessible();
    getLessonList();
  }, [params.subjectID]);

  const mytoken = localStorage.getItem("token");
  const headers = {
    "access-token": "qwertasdfg123@+1",
    Authorization: `Bearer ${mytoken}`,
  };

  const getLessonList = async () => {
    await axios
      .get(
        `https://emmobank.com/api/get_exam_list?subject_id=${params.subjectID}&grade_id=${params.school}`,
        {
          headers: headers,
        }
      )
      .then(async (respons) => {
        setLessonsList(respons.data.exams);
        setLanguage(respons.data.lang);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getLastUserScore = async () => {
    setRetake(Array(lessonsList[params.subjectID]?.length).fill(false));
    getScoreFromServer();
  };

  const getScoreFromServer = async () => {
  };



  const checkAccessible = () => {};
  return (
    <div
      dir={language === "ar" ? "rtl" : "ltr"}
      className="flex flex-col justify-start h-full w-full p-0 m-0 md:max-w-[875px] m-auto overflow-hidden pb-20"
    >
      <NavBar
        menu={false}
        qr={false}
        titleText={books.filter((book) => book.id == params.subjectID)[0]?.name}
        bookmarkIcon={false}
        title={true}
        id={params.subjectID}
        back={true}
        language={language}
        to={"/subjects/" + currentDivision}
      />
      {loading ? (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-12 h-12 m-auto mt-32 flex animate-spin stroke-primary"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        </>
      ) : (
        <>
          <div className={`flex flex-col gap-3 w-full h-fit px-4 py-3`}>
            <Accordion items={lessonsList} />
          </div>
        </>
      )}
    </div>
  );
};
