import { useEffect, useState } from "react";
 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setupNotifications } from "./FirebaseConfig";
import {
  toastNotification,
  sendNativeNotification,
} from "./notificationHelpers";
import useVisibilityChange from "./useVisibilityChange";
export default function FirebaseToast() {

  const isForeground = useVisibilityChange();
   

  useEffect(() => {
     
    setupNotifications((message) => {
      if (isForeground) {
        toastNotification({
          title: "title",
          description: "body",
          status: "info",
        });
      } else {
        sendNativeNotification({
          title: "title",

          body: "body ",
        });
      }
    });
  }, []);

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={2998}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}
