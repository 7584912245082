import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { lessons } from "../data";
import { useDispatch } from "react-redux";
import { setShowTool } from "../store/actions";

export const BackNavBar = ({ btns, language, canBack }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();

  const backTo = () => {
    dispatch(setShowTool(false));
    localStorage.setItem("stopTimerFromBackButton", false);
    window.timer = false;
    if (localStorage.getItem("comeFrom")) {
      navigate(localStorage.getItem("comeFrom"));
    } else {
      if (params.subjectID) {
        if (localStorage.getItem("grade_id")) {
          navigate(
            "/lessons/" +
              localStorage.getItem("grade_id") +
              "/" +
              params.subjectID
          );
        } else {
          navigate("/lessons/" + params.subjectID);
        }
      } else {
        navigate("/home");
      }
    }
  };

  useEffect(() => {
    window.timer = false;
    localStorage.setItem("stopTimerFromBackButton", false);
    if (params.subjectID && params.id) {
      setTitle(
        lessons[params.subjectID]?.filter((lesson) => lesson.id == params.id)[0]
          .name || ""
      );
    }
  }, [params]);

  return (
    <div
      dir={`${language === "ar" ? "rtl" : "ltr"}`}
      className={`z-[99999] flex w-full   top-0 px-4 py-3 bg-primary flex-col justify-start gap-3 rounded-b-lg    `}
    >
      <div className="flex flex-row justify-start items-center w-full">
        <div className="flex flex-row w-full justify-start gap-6">
          {canBack ? (
            <button
              onClick={() => backTo()}
              className="capitalize flex w-fit shrink-0 flex-row gap-2 text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={`w-6 h-6 ${language === "ar" ? "rotate-180" : ""}`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                />
              </svg>
            </button>
          ) : null}
          <div className="flex w-full justify-start -mt-1 font-semibold text-white text-[21px] font-[Almarai]">
            {title}
          </div>
        </div>
        <div className="min-w-fit">{btns}</div>
      </div>
    </div>
  );
};
