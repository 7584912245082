export const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
       
      
       if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.addEventListener('message', (event) => {
          if (event.data && event.data.type === 'SAVE_TO_LOCAL_STORAGE') {
            if (typeof event.data.questionId === 'string') {
              localStorage.setItem('question_id', event.data.questionId);
            } else {
              console.warn('Invalid questionId:', event.data.questionId);
            }
          }
        });
      } else {
        console.warn('Service Worker is not controlling the page.');
      }

      return registration;
    } catch (error) {
      console.error("Error registering service worker:", error);
      throw error;
    }
  } else {
    console.error('Service Worker is not supported in this browser.');
  }
};
