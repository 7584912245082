import React, { useEffect } from 'react';

const DisablePullToRefresh = ({ children }) => {
  useEffect(() => {
    const disablePullToRefresh = (e) => {
       if (e.touches.length > 1 || e.touches[0].clientY > 0) {
        e.preventDefault();
      }
    };

     document.addEventListener("touchmove", disablePullToRefresh, { passive: false });
     return () => {
      document.removeEventListener("touchmove", DisablePullToRefresh);
    };
  }, []);

  return (
    <div style={{ touchAction: 'pan-x' }}>
      {children}
    </div>
  );
};

export default DisablePullToRefresh;
