import React, { useState, useEffect } from "react";
 import {  useSelector } from "react-redux";
import configLang from "../../../sql/config.json";
import { localStorageAnswered } from "../AglopalQuestionMethod/localStorageAnswered";
import { QuesHead } from "../QuesHead";
import { ResizeImage } from "../../resizeImage";
   
export const RightWrong = (props) => {
  const { question, multipart } = props;
  const [myAnswer, setMyAnswer] = useState(null);
  const [model, setModel] = useState(false);
  const [modelBody, setModelBody] = useState(null);
  const numeric = {
    ar: ["ا", "ب", "جـ", "د", "هـ"],
    en: ["a", "b", "c", "d", "e"],
  };
  // console.log(question.QuesRightAns);
  const { config } = configLang;
  const {   currentQues } = useSelector(
    (state) => state.userAppReducer
  );
  const [loading, setLoading] = useState(false);

const briefReport=true
const lang="en"

  useEffect(() => {
    getUserAnswer(question.id);
  }, [question.id, currentQues]);

  const getUserAnswer = async (id) => {
    setLoading(false);
    let my = JSON.parse(localStorage.getItem(id));
    setMyAnswer(my);
    setLoading(false);
  };
 
  const registerAnswer = (uuid, index, multipart) => {
    localStorage.setItem(
      uuid,
      JSON.stringify({
        id: uuid,
        uuid: uuid,
        index: index,
        right: parseInt(numeric[lang].indexOf(question.QuesRightAns)),
        multipart: multipart,
      })

    );
    localStorageAnswered(question.id)
      setMyAnswer({ id: uuid, uuid: uuid, index: index, multipart: multipart });
  };

  const getAnswerLetter = (a) => {
    if (lang === "ar") {
      return parseInt(["ا", "ب", "جـ", "د", "هـ"].indexOf(a?.toLowerCase()));
    } else {
      return parseInt(["a", "b", "c", "d", "e"].indexOf(a?.toLowerCase()));
    }
  };

  const ChoiceRender = ({ index, choice, uuid, answer }) => {
    // console.log({ index, choice, uuid, answer: answer?.toLowerCase() });
    // console.log(myAnswer?.index);
    // console.log( getAnswerLetter(answer));
    // console.log(myAnswer?.index);
    
    

    if (briefReport) {
      if (
        getAnswerLetter(answer) === myAnswer?.index &&
        myAnswer?.index === index
      ) {
        return (
          <div className="w-full p-3 text-black bg-Light border rounded-lg   flex items-center border-primary">
            <button
              key={index}
              className={`w-fit select-none flex ${
                question.numeric_type
                  ? "flex-row-reverse content-end self-end"
                  : "flex-row  content-start self-start"
              } justify-start items-center gap-3 mt-2 `}
            >
              <div
                className={`rtl:-pt-[4px] text-white bg-green-500  border-green-500 border rounded-full relative self-center flex w-7 h-7 shrink-0 justify-center items-center ${numericFont} `}
              >
                {numeric[numericFunction(question.numeric_type, lang)][index]}
              </div>
              <div>
                <ResizeImage
                  size="rem"
                  text={choice}
                  type={"answer"}
                  ques={question}
                />
              </div>
            </button>
          </div>
        );
      } else if (
        getAnswerLetter(answer) !== myAnswer?.index &&
        myAnswer?.index === index
      ) {
        return (
          <div className="w-full p-3 text-black     rounded-lg   flex items-center bg-red-200 border-2 border-red-300">
            <button
              key={index}
              className={`w-fit select-none flex ${
                question.numeric_type
                  ? "flex-row-reverse content-end self-end"
                  : "flex-row  content-start self-start"
              } justify-start items-center gap-3 mt-2`}
            >
              <div
                className={`rtl:-pt-[4px] text-white bg-red-500 border-red-500 border rounded-full relative  self-center flex w-7 h-7 shrink-0 justify-center items-center ${numericFont} `}
              >
                {numeric[numericFunction(question.numeric_type, lang)][index]}
              </div>
              <ResizeImage
                size="rem"
                text={choice}
                type={"answer"}
                ques={question}
              />
            </button>
          </div>
        );
      } else if (
        getAnswerLetter(answer) === index &&
        index !== myAnswer?.index
      ) {
        return (
          <div className="w-full p-3 text-black bg-green-100 border rounded-lg   flex items-center border-green-300">
            <button
              key={index}
              className={`w-fit select-none flex ${
                question.numeric_type
                  ? "flex-row-reverse content-end self-end"
                  : "flex-row  content-start self-start"
              } justify-start items-center gap-3 mt-2`}
            >
              <div
                className={`rtl:-pt-[4px] text-white bg-green-500  border-green-500 border rounded-full relative self-center flex w-7 h-7 shrink-0 justify-center items-center ${numericFont} `}
              >
                {numeric[numericFunction(question.numeric_type, lang)][index]}
              </div>

              <ResizeImage
                size="rem"
                text={choice}
                type={"answer"}
                ques={question}
              />
            </button>
          </div>
        );
      } else {
        return (
          <div className="w-full p-3 text-black  border rounded-lg   flex items-center">
            <button
              key={index}
              className={`w-fit select-none flex ${
                question.numeric_type
                  ? "flex-row-reverse content-end self-end"
                  : "flex-row  content-start self-start"
              } justify-start items-center gap-3 mt-2`}
              disabled={briefReport}
              onClick={() => {
                registerAnswer(uuid, index, multipart);
              }}
            >
              <div
                className={`  rtl:-pt-[4px] text-black bg-white  border-black } 
                            border rounded-full relative self-center flex w-7 h-7 shrink-0 justify-center items-center ${numericFont} `}
              >
                {numeric[numericFunction(question.numeric_type, lang)][index]}
              </div>
              <ResizeImage
                size="rem"
                text={choice}
                type={"answer"}
                ques={question}
              />
            </button>
          </div>
        );
      }
    } else {
      return (
        <div
          className={`flex   p-3 w-full  ${
            myAnswer?.index == index && myAnswer?.uuid == uuid
              ? "text-black bg-Light border rounded-lg   flex items-center"
              : " "
          }`}
          onClick={() => {
            registerAnswer(uuid, index, multipart);
          }}
        >
          <button
            key={index}
            className={`w-fit select-none flex ${
              question.numeric_type
                ? "flex-row-reverse content-end self-end"
                : "flex-row  content-start self-start"
            } justify-start items-center gap-3 `}
            disabled={briefReport}
            num={question.numeric_type}
            onClick={() => {
              registerAnswer(uuid, index, multipart);
            }}
          >
            <div
              className={` rtl:-pt-[4px]
                        ${
                          myAnswer?.index == index && myAnswer?.uuid == uuid
                            ? "text-white bg-primary border-primary"
                            : "text-black bg-white border-black"
                        }
                        border rounded-full relative self-center flex w-7 h-7 shrink-0 justify-center items-center  ${numericFont} `}
            >
              {numeric[numericFunction(question.numeric_type, lang)][index]}
            </div>
            <ResizeImage
              size="rem"
              text={choice}
              type={"answer"}
              ques={question}
            />
          </button>
        </div>
      );
    }
  };

  const numericFunction = (a, b) => {
    if (a == 0) {
      return b;
    } else if (a == 1) {
      return "ar";
    } else if (a == 2) {
      return "en";
    } else {
  //     console.log(a, b);
      return b;
    }
  };
  const numericFont = (a, b) => {
    if (a == 0) {
      return "font-[roman] rtl:font-[Droid]";
    } else if (a == 1) {
      return "font-[Droid]";
    } else if (a == 2) {
      return "font-[roman]";
    } else {
      return "font-[roman] rtl:font-[Droid]";
    }
  };
  return (
    <>
      <div className="   p-1 flex-col flex gap-2 px-2 relative ">
        {loading ? (
          <>
            <div className="bg-slate-100 flex w-full h-full top-0 left-0 z-50 absolute rounded-lg flex-col justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10 animate-spin"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </div>
          </>
        ) : (
          <></>
        )}
        
                
        
        <ResizeImage
          size="rem"
          text={question.QuesTxt}
          type={"question"}
          ques={question}
        />
        <div className="w-full h-0 border-b" />
        <div className="flex flex-col gap-1 justify-start items-center pb-2 ">
          {question.QuesAns.map((choice, index) => (
            <ChoiceRender
              answer={question.QuesRightAns}
              key={index}
              uuid={question.id}
              choice={choice.txt}
              index={index}
            />
          ))}
        </div>
        {briefReport ? (
          <>
            {question.QuesShowAns1.status ? (
              <>
                <div
                  onClick={() => {
                    setModelBody(question.QuesShowAns1.txt);
                    setModel(true);
                  }}
                  className="bg-white border border-black rounded-full px-5 py-2 w-fit mb-2 self-end"
                >
                  {config[lang].showAnswer}
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>

      {model ? (
        <>
          <div className="absolute w-screen h-screen top-0 left-0 bg-black bg-opacity-50 z-[99999] flex flex-col justify-center items-center">
            <div
              dir={lang === "ar" ? "rtl" : "ltr"}
              className={`bg-white shadow-2xl border border-company-red p-3 rounded-2xl w-[500px] min-h-[10vh] max-w-[90vw] flex flex-col gap-2  ease-in-in duration-500 delay-100  opacity-1 mt-0 relative`}
            >
              <div className="absolute m-auto -top-7 fill-white self-center flex border border-transparent border-t-company-red border-r-company-red -rotate-45 rounded-full p-0 bg-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-14 h-14 stroke-orange-400 rotate-45"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </svg>
              </div>
              <div className="text-center text-lg py-8">
                <ResizeImage size="rem" text={modelBody} />
              </div>
              <div className="h-10" />
              <div
                onClick={() => {
                  setModelBody(null);
                  setModel(false);
                }}
                className="absolute m-auto -bottom-7 fill-white self-center flex border border-transparent border-b-company-red border-l-company-red -rotate-45 rounded-full p-0 bg-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-14 h-14 stroke-company-red rotate-45"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
