// localStorageUtils.js

/**
 * @param {Object} metadata
 * @param {string} value -
 */

const getExamMeta = () => {};

export function localStorageAnswered(value) {
  try {
    const examMeta = getExamMeta();
    const key = examMeta.examID;

    let array = JSON.parse(localStorage.getItem(`Answered_ID_${key}`)) || [];

    if (!array.includes(value)) {
      array.push(value);
      localStorage.setItem(`Answered_ID_${key}`, JSON.stringify(array));

      const lengthOFAnswered = JSON.parse(
        localStorage.getItem(`Answered_ID_${key}`)
      )?.length;
      const lengthOFQuestions = localStorage.getItem(`${key}_questions_length`);
      const progressPercentage =
        (parseInt(lengthOFAnswered) / parseInt(lengthOFQuestions)) * 100;

      localStorage.setItem(`progressPercentage_OF_${key}`, progressPercentage);
    }
  } catch (error) {
    console.error("Error in localStorageAnswered:", error);
  }
}
