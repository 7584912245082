import React, { useEffect, useState } from "react";
import { localStorageAnswered } from "../AglopalQuestionMethod/localStorageAnswered";
import { ResizeImage } from "../../resizeImage";
   
const Order = ({ question,options }) => {

  // console.log(question);

  const [items, setItems] = useState([]);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [ItsMove, setItsMove] = useState(null);
   const [dragAndDropEnabled, setDragAndDropEnabled] = useState(!options.fullReport);
 
   useEffect(() => {
    
   setItems(question.QuesAns)
   
   }, [question.id]);
  // Mouse event handlers
  const handleDragStart = (event, index) => {
    if (!dragAndDropEnabled) return;

    setDraggedIndex(index);
    event.dataTransfer.setData("draggedItemIndex", index);
  };

  const handleDragOver = (event) => {
    if (!dragAndDropEnabled) return;
    
    event.preventDefault();
  };

  const handleDrop = (event, index) => {
    if (!dragAndDropEnabled) return;

    event.preventDefault();
    const draggedItemIndex = event.dataTransfer.getData("draggedItemIndex");
    const tempItems = [...items];
    const [draggedItem] = tempItems.splice(draggedItemIndex, 1);
    tempItems.splice(index, 0, draggedItem);

    setItems(tempItems);
    setDraggedIndex(null);
    setHoveredIndex(null);
    // console.log("New order:", tempItems);
    const numArray = tempItems.map((item) => item.num);
    // console.log("Array of nums:", numArray);
    localStorage.setItem(question.QuesID, JSON.stringify(numArray));
    localStorageAnswered(question.id)
  };

  const handleDragEnter = (index) => {
    if (!dragAndDropEnabled) return;
    setHoveredIndex(index);
  };

  const handleDragLeave = () => {
    if (!dragAndDropEnabled) return;
    setHoveredIndex(null);
  };

  const handleDragEnd = () => {
    if (!dragAndDropEnabled) return;
    setDraggedIndex(null);
    setHoveredIndex(null);
  };

  // Touch event handlers
  const handleTouchStart = (event, index) => {
 
    // console.log("handleTouchStart");
    if (!dragAndDropEnabled) return;
    setDraggedIndex(index);
    event.preventDefault();
  };

  const handleTouchMove = (event,index) => {
    if (!dragAndDropEnabled) return;
    setItsMove(index)
    event.preventDefault();
  };

  const handleTouchEnd = (event, index) => {
    if (!dragAndDropEnabled) return;

    event.preventDefault();
    const touch = event.changedTouches[0];
    const touchY = touch.clientY;
    const tempItems = [...items];
    
    // Find the closest index based on touch position
    const dropIndex = tempItems.findIndex((item, idx) => {
      const rect = document.getElementById(`item-${idx}`)?.getBoundingClientRect();
      return touchY > rect.top && touchY < rect.bottom;
    });

    if (dropIndex === -1) return; 

    const [draggedItem] = tempItems.splice(draggedIndex, 1);
    tempItems.splice(dropIndex, 0, draggedItem);

    setItems(tempItems);
    setDraggedIndex(null);
    setHoveredIndex(null);
    const numArray = tempItems.map((item) => item.num);

    localStorage.setItem(question.QuesID, JSON.stringify(numArray));
    localStorageAnswered(question.id)
 
  };

  const displayedItems = true
  ? [...items].sort((a, b) => a.num - b.num)
  : items;
   

  return (
    <div className=" flex-col flex px-2 relative">
      <ResizeImage size="rem" type="question" text={question.QuesTxt} />
       
       {displayedItems.map((item, index) => (
        <div
          id={`item-${index}`}
          key={item.num}
          className={`px-3 flex flex-col justify-center items-center }  ${
            draggedIndex === index
              ? ""
              : hoveredIndex === index
              ? "border-dashed border-2 border-indigo-600"
              : ""
          }`}
        >
        <div className="w-full flex flex-row items-center">
        <div className="font-medium text-2xl pr-3"> {(index+1)+"."}</div>
          <div
            className={` w-full border-2 m-2 p-1 border-black rounded-xl flex flex-row gap-3 ${
              draggedIndex === index
                ? "bg-slate-300"
                : hoveredIndex === index
                ? "border-dashed border-2 border-indigo-600 hover:bg-black"
                : "border"
            } ${ItsMove===index&&" border-indigo-600"}`}
            draggable={dragAndDropEnabled}
            onDragStart={(event) => handleDragStart(event, index)}
            onDragOver={handleDragOver}
            onDrop={(event) => handleDrop(event, index)}
            onDragEnter={() => handleDragEnter(index)}
            onDragLeave={handleDragLeave}
          
            onDragEnd={handleDragEnd}
            onTouchStart={(event) => handleTouchStart(event, index)}
            onTouchMove={(event) => handleTouchMove(event,index)}
            onTouchEnd={(event) => handleTouchEnd(event, index)}

            style={{
              userSelect: "none",
              padding: "11px",
              margin: "0 0 8px 0",
              backgroundColor:
                draggedIndex === index
                  ? "border-2 border-indigo-600"
                  : hoveredIndex === index
                  ? ""
                  : "",
              border: "1px solid #ccc",
              cursor: draggedIndex === index ? "grabbing" : "move",
              transition: "background-color 0.2s ease",
            }}
          >
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_9971_107)">
                <path d="M6.66667 3.83333C7.58714 3.83333 8.33333 3.08714 8.33333 2.16667C8.33333 1.24619 7.58714 0.5 6.66667 0.5C5.74619 0.5 5 1.24619 5 2.16667C5 3.08714 5.74619 3.83333 6.66667 3.83333Z" fill="#495057"/>
                <path d="M13.3333 3.83333C14.2538 3.83333 15 3.08714 15 2.16667C15 1.24619 14.2538 0.5 13.3333 0.5C12.4128 0.5 11.6667 1.24619 11.6667 2.16667C11.6667 3.08714 12.4128 3.83333 13.3333 3.83333Z" fill="#495057"/>
                <path d="M6.66667 12.1663C7.58714 12.1663 8.33333 11.4201 8.33333 10.4997C8.33333 9.5792 7.58714 8.83301 6.66667 8.83301C5.74619 8.83301 5 9.5792 5 10.4997C5 11.4201 5.74619 12.1663 6.66667 12.1663Z" fill="#495057"/>
                <path d="M13.3332 12.1663C14.2536 12.1663 14.9998 11.4201 14.9998 10.4997C14.9998 9.5792 14.2536 8.83301 13.3332 8.83301C12.4127 8.83301 11.6665 9.5792 11.6665 10.4997C11.6665 11.4201 12.4127 12.1663 13.3332 12.1663Z" fill="#495057"/>
                <path d="M6.66667 20.5003C7.58714 20.5003 8.33333 19.7541 8.33333 18.8337C8.33333 17.9132 7.58714 17.167 6.66667 17.167C5.74619 17.167 5 17.9132 5 18.8337C5 19.7541 5.74619 20.5003 6.66667 20.5003Z" fill="#495057"/>
                <path d="M13.3332 20.5003C14.2536 20.5003 14.9998 19.7541 14.9998 18.8337C14.9998 17.9132 14.2536 17.167 13.3332 17.167C12.4127 17.167 11.6665 17.9132 11.6665 18.8337C11.6665 19.7541 12.4127 20.5003 13.3332 20.5003Z" fill="#495057"/>
              </g>
              <defs>
                <clipPath id="clip0_9971_107">
                  <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
              </defs>
            </svg>
               
            <div
                 dangerouslySetInnerHTML={{
                  __html: item.txt,
                }}
              /> 
          </div>
        </div>
        </div>
      ))}
    </div>
  );
};

export default Order;
