import React, { useState, useEffect } from "react";
 import { useDispatch, useSelector } from "react-redux";
import configLang from "../../../sql/config.json";
import { localStorageAnswered } from "../AglopalQuestionMethod/localStorageAnswered";
import { ResizeImage } from "../../resizeImage";
  
export const MAQ = (props) => {
  const { question, multipart } = props;
  // console.log(question.QuesRightAns);

  const [myAnswer, setMyAnswer] = useState(null);
  const numeric = {
    ar: ["ا", "ب", "جـ", "د", "هـ"],
    en: ["a", "b", "c", "d", "e"],
    fr: ["a", "b", "c", "d", "e"],
  };
  const [maqAnswer, setMaqAnswer] = useState([null, null, null, null, null]);
  const { lang, briefReport, currentQues } = useSelector(
    (state) => state.userAppReducer
  );
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(false);
  const [modelBody, setModelBody] = useState(null);
  const { config } = configLang;
 
  useEffect(() => {
    setLoading(false);
    setMaqAnswer([null, null, null, null, null]);
    getUserAnswer(question.id);
  }, [question.id, currentQues]);

  const getUserAnswer = async (id) => {
    setLoading(false);
    let my = JSON.parse(localStorage.getItem(id));
    setMyAnswer(my);
    setLoading(false);
  };

  const registerAnswer = async (uuid, index, multipart) => {
    let a = maqAnswer;
    a[index] = a[index] == true ? null : true;
    let right = question.QuesRightAns.map((c) => (c === "true" ? true : null));
    localStorage.setItem(
      uuid,
      JSON.stringify({
        id: uuid,
        uuid: uuid,
        answers: a,
        right: right,
        multipart: multipart,
      })
    );
    localStorageAnswered(question.id)
 
    setMyAnswer({ id: uuid, uuid: uuid, answers: a, multipart: multipart });
  };

  const ChoiceRender = ({ index, choice, uuid, answer }) => {
    const arrayOfBooleans = answer.map((value) => value === "true");
    if (true) {
      if (
        myAnswer?.answers[index] != null &&
        arrayOfBooleans[index] === myAnswer?.answers[index]
      ) {
        return (
          <div className="w-full p-3 text-black rounded-lg   flex items-center bg-lightInfo border-2 border-[#43AA4E]">
            <button
              key={index}
              className={`w-fit select-none flex flex-row justify-start items-start content-start self-start gap-3 `}
            >
              <div
                className={`rtl:-pt-[4px] text-white bg-green-500 border-green-500 border rounded-lg relative  self-center flex w-7 h-7 shrink-0 justify-center items-center font-[roman] rtl:font-[Droid]`}
              >
                {numeric[lang][index]}
              </div>
              <ResizeImage size="rem" type="answer" text={choice} />
            </button>
          </div>
        );
      } else if (
        myAnswer?.answers[index] != null &&
        arrayOfBooleans[index] != myAnswer?.answers[index]
      ) {
        return (
          <div className="w-full p-3 text-black     rounded-lg   flex items-center bg-lightDanger border-2 aborder-danger">
            <button
              key={index}
              className={`w-fit select-none flex flex-row justify-start items-start content-start self-start gap-3`}
            >
              <div
                className={`rtl:-pt-[4px] text-white bg-red-500border-red-500 border rounded-lg relative  self-center flex w-7 h-7 shrink-0 justify-center items-center font-[roman] rtl:font-[Droid]`}
              >
                {numeric[lang][index]}
              </div>
              <ResizeImage size="rem" type="answer" text={choice} />
            </button>
          </div>
        );
      } else {
        return (
          <div className="w-full p-3 text-black     rounded-lg   flex items-center bg-red-200a border-2 aborder-red-300">
            <button
              key={index}
              className={`w-fit select-none flex flex-row justify-start items-start content-start self-start gap-3`}
              disabled={briefReport}
              onClick={() => {
                registerAnswer(uuid, index, multipart);
              }}
            >
              <div
                className={` rtl:-pt-[4px] border ${
                  arrayOfBooleans[index] == true
                    ? "bg-green-500 border-green-500 text-white"
                    : "text-black bg-white border-black"
                } }
                            border rounded-lg relative  self-center flex w-7 h-7 shrink-0 justify-center items-center font-[roman] rtl:font-[Droid]`}
              >
                {numeric[lang][index]}
              </div>
              <ResizeImage size="rem" type="answer" text={choice} />
            </button>
          </div>
        );
      }
    } else {
      return (
        <div
          className={`flex   p-3 w-full ${
            myAnswer?.answers?.[index] === true && myAnswer?.uuid === uuid
              ? "text-black bg-Light border rounded-lg   flex items-center border-primary"
              : " "
          }`}
          onClick={() => {
            registerAnswer(uuid, index, multipart);
          }}
        >
          <button
            key={index}
            className={`w-fit select-none flex flex-row justify-start items-center content-start self-start gap-3`}
          >
            <div
              className={` rtl:-pt-[4px] ${
                myAnswer?.answers?.[index] === true && myAnswer?.uuid === uuid
                  ? "text-white bg-primary border border-primary"
                  : "text-black bg-white border border-2 border-gray-400"
              } rounded-lg relative  self-center flex w-7 h-7 shrink-0 justify-center items-center font-[roman] rtl:font-[Droid]  `}
            >
              {numeric[lang][index]}
            </div>
            <ResizeImage size="rem" type="answer" text={choice} />
          </button>
        </div>
      );
    }
  };

  return (
    <>
      <div className=" relative p-1  bg-white    rounded-b-xl  shadow-lg">
        {loading ? (
          <>
            <div className="bg-slate-100 flex w-full h-full top-0 left-0 z-50 absolute rounded-lg flex-col justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10 h-10 animate-spin"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </div>
          </>
        ) : (
          <></>
        )}
        <ResizeImage size="rem" type="ques" text={question.QuesTxt} />
        <div className="w-full h-0 border-b" />
        <div className="flex flex-col gap-1 justify-start items-center pb-2 ">
          {question.QuesAns.map((choice, index) => (
            <ChoiceRender
              answer={question.QuesRightAns}
              key={index}
              uuid={question.id}
              choice={choice.txt}
              index={index}
            />
          ))}
        </div>
        {briefReport  ? (
          <>
            {question.QuesShowAns1.status ? (
              <>
                <div
                  onClick={() => {
                    setModelBody(question.QuesShowAns1.txt);
                    setModel(true);
                  }}
                  className="bg-white border border-black rounded-full px-5 py-2 w-fit mb-2 self-end"
                >
                  {config[lang].showAnswer}
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      {model ? (
        <>
          <div className="absolute w-screen h-screen top-0 left-0 bg-black bg-opacity-50 z-[99999] flex flex-col justify-center items-center">
            <div
              dir={lang === "ar" ? "rtl" : "ltr"}
              className={`bg-white shadow-2xl border border-company-red p-3 rounded-2xl w-[500px] min-h-[10vh] max-w-[90vw] flex flex-col gap-2  ease-in-in duration-500 delay-100  opacity-1 mt-0 relative`}
            >
              <div className="absolute m-auto -top-7 fill-white self-center flex border border-transparent border-t-company-red border-r-company-red -rotate-45 rounded-full p-0 bg-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-14 h-14 stroke-orange-400 rotate-45"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </svg>
              </div>
              <div className="text-center text-lg py-8">
                <ResizeImage size="rem" text={modelBody} />
              </div>
              <div className="h-10" />
              <div
                onClick={() => {
                  setModelBody(null);
                  setModel(false);
                }}
                className="absolute m-auto -bottom-7 fill-white self-center flex border border-transparent border-b-company-red border-l-company-red -rotate-45 rounded-full p-0 bg-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-14 h-14 stroke-company-red rotate-45"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
