 


import $ from "jquery";
import React, { useEffect, useState } from "react";
import {  useSelector } from "react-redux";
 // import config from "../sql/config.json";
//  import { Title } from "./Title";
import { ResizeImage } from '../../resizeImage';

const Diagram  = (props) => {
 
  // @ts-ignore
  const {
    modal,
    blurExam,
    currentQues,
    userAnswer,
    fullReport,
    briefReport,
    examConfig,
  } = useSelector((state) => state.userAppReducer);
  const [imageLoaded, setiImageLoaded] = useState(true);
  const [loaded, setLoaded] = useState(true);
  const [current_is_answerd, setCurrent_is_answerd] = useState([]);

  useEffect(() => {
    setCurrent_is_answerd(
      userAnswer.filter((e) => e != null && e.index == currentQues)[0]
    );
    window.imagesCount = 0;
    setTimeout(() => {
      setLoaded(false);
    }, 50);
    checkImageCount();
  }, [loaded]);

  const checkImageCount = () => {
    $("#checkImageCount").html(props.question.QuesTxt);
    var img = $("img");  
    var images = $("#checkImageCount").find("img");
    if (images.length > 0) {
      img.on("load", function () {
        if (
          this.complete &&
          typeof this.naturalWidth !== "undefined" &&
          this.naturalWidth > 0
        ) {
          window.imagesCount = window.imagesCount + 1;
          if (window.imagesCount >= images.length) {
            setTimeout(() => {
              setiImageLoaded(false);
            }, 500);
          }
        }
      });
    } else {
      setiImageLoaded(false);
    }
  };

  return (
    <div className="w-full pt-4 flex flex-col">
      <div id="checkImageCount" className="hidden"></div>
      <div className="w-full flex flex-col justify-start gap-4 ">
       
        <div className="    flex flex-col p-2 gap-4    mx-3  ">
          {fullReport === false && examConfig.marked === true ? (
            <div
              className={`absolute text-sm py-1 -top-[19px] border bg-white ${
                props.lang == "ar"
                  ? "left-5 font-[Droid]"
                  : "right-5 font-[roman]"
              } px-5 rounded-full gap-1 flex flex-row z-10`}
            >
              <span
                className={`${
                  props.lang === "ar"
                    ? "left-5 font-[ArabicEqu]"
                    : "right-5 font-[roman]"
                } flex`}
              >
                {props.lang === "ar"
                  ? ![1, 2].includes(parseInt(props.question.QuesScore))
                    ? props.question.QuesScore
                    : ""
                  : props.question.QuesScore}
              </span>
              {/* <span className="flex">
                {props.lang === "ar"
                  ? parseInt(props.question.QuesScore) === 1
                    ? "درجة واحدة"
                    : parseInt(props.question.QuesScore) === 2
                    ? "درجتان"
                    : config.config["ar"].Degrees
                  : [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(
                      parseInt(props.question.QuesScore)
                    )
                  ? config.config["en"].Degrees
                  : config.config["en"].Degree}
              </span> */}
            </div>
          ) : (
            ""
          )}
          {imageLoaded == true ? (
            <div className="bg-white absolute w-full h-full p-0 m-0 -ml-2 -mt-2 rounded-xl z-40 opacity-90 flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-12 h-12 animate-spin stroke-primary"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </div>
          ) : (
            ""
          )}
           <ResizeImage
            size="rem"
            type="ques"
            text={props.question.QuesTxt}
            question={props.question}
          />
        </div>
      </div>
    </div>
  );
};


export default Diagram;
