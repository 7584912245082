import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { localStorageAnswered } from './../AglopalQuestionMethod/localStorageAnswered';
 
export const ResizeTable = (props) => {
  const indexOfOBJ = props.indexOfOBJ;
  const questionID = props.questionID;
  const length = props.length;


  const local = JSON.parse(localStorage.getItem(questionID));
  console.log(local);


  // const matches = htmlCode.match(/##(.*?)##/g);
  // const blanksCount = matches ? matches.length : 0;

  // const localAnswersStringify = localStorage.getItem(questionID);
  // const localAnswers = localAnswersStringify
  //   ? JSON.parse(localAnswersStringify)
  //   : Array(length).fill(Array(length).fill([]));

  //   localStorage.setItem(questionID, JSON.stringify(localAnswers));


  const question_quesRightAns_props = props.Item.QuesRightAns || [];

  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );
  const [hello, sethello] = useState();
  const { currentQues, fullReport, briefReport, examConfig, endExam } =
    useSelector((state) => state.userAppReducer);

  const userAnswer = JSON.parse(localStorage.getItem(questionID));
  console.log(userAnswer);

   useEffect(() => {
    window.imagesCount = 0;
    resizeImage();
  }, [isLandscape]);
  useEffect(() => {
//     console.log("---------------");
  }, [hello]);

  useEffect(() => {
    function handleOrientationChange(mql) {
      setIsLandscape(mql.matches);
    }
    const mediaQueryList = window.matchMedia("(orientation: portrait)");
    mediaQueryList.addListener(handleOrientationChange);

    // Clean up the listener function when the component unmounts
    return () => {
      mediaQueryList.removeListener(handleOrientationChange);
    };
  }, []);

  const resizeImage = (text, type, size) => {
    // return text;
    $("#txt").html(text);

    if (!window.blanksTable) {
      window.blanksTable = function (
        myAnswer,
        rightAnswer,
        indexOfOBJ,
        iOfBlank,
        blanksCount,
        questionID
      ) {
        localStorageAnswered(questionID);
    //     console.log("indexOfOBJ, iOfBlank", blanksCount);

        const localAnswersStringify = localStorage.getItem(questionID);
        const localAnswers = localAnswersStringify
          ? JSON.parse(localAnswersStringify)
          : Array(length).fill([]);

        let ARRrightAnswer = [];
        ARRrightAnswer.push(rightAnswer);
        const array_QuesRight = question_quesRightAns_props[iOfBlank] || [];
    //     console.log(array_QuesRight);
        let mergedArray = [...ARRrightAnswer, ...array_QuesRight];

    //     console.log(mergedArray);

        const entry = {
          answer: myAnswer,
          parent: false,
          right: mergedArray,
          type: "blanks",
        };

        
         localAnswers[indexOfOBJ][iOfBlank] = entry;
        localStorage.setItem(questionID, JSON.stringify(localAnswers));

        return true;
      };
    }
    let index = 0;

    const addbiefReport = (userAnswer) => {
      if (!briefReport) {
        return "border-black ";
      }

      return userAnswer?.right === userAnswer?.answer && userAnswer
        ? "border-green-500 border-2"
        : "border-red-500 border-2";
    };

    let htmlCode = $("#txt").html();
    if (htmlCode?.includes("##")) {
      // console.log(true);
      const matches = htmlCode.match(/##(.*?)##/g);
      const blanksCount = matches ? matches.length : 0;
      let a = htmlCode.replace(/##(.*?)##/g, (match, rightAnswer) => {
        let indexOfBlank = index++;

        const userAnsLength = Math.min(
          userAnswer?.[currentQues]?.[indexOfBlank]?.answer
            ? userAnswer?.[currentQues]?.[indexOfBlank]?.answer?.length * 10 +
                50
            : rightAnswer?.length * 10 + 50,
          400
        );
        const ansLength = Math.min(rightAnswer?.length * 10 + 50, 400);
        // console.log("mmmmm", userAnsLength, ansLength);
        const rightIconBox = `<svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        style="position: absolute;top:-3px; right: -3px; "
        class="
                    w-5 h-5 border -top-2 -right-2 z-10 absolute bg-white rounded-full
                    stroke-green-600   "
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.5 12.75l6 6 9-13.5"
        />
      </svg>`;
        const wrongIconBox = `<svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        style="position: absolute;top:-3px; right: -3px; "
        class="
                    w-5 h-5 border -top-2 -right-2 z-10 absolute bg-white rounded-full
                    stroke-red-600   "
      >
      <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 18L18 6M6 6l12 12"
    />
      </svg>`;
        const rightInput = `<span style="width:fit-content; display:inline-block; position: relative"><input   value=${rightAnswer} type="text" style="width:${ansLength}px; text-align: center" class="border border-green-600 bg-green-100 h-9 max-w-full mx-1 inline-block   border-dashed rounded-lg py-1 px-1 font-extrabold"/>${rightIconBox}</span>`;

        return `<span style="width:fit-content; display:inline-block; position: relative" >
        
        <input       ${endExam?"disabled":""}    value="${
          userAnswer?.[indexOfOBJ][indexOfBlank]?.answer || ""
        }" style="width:${userAnsLength}px; text-align: center" onkeyup="window.blanksTable(this.value,'${match.replace(
          /##/g,
          ""
        )}',${indexOfOBJ},${indexOfBlank},${blanksCount},${questionID})" type="text" class=" ${addbiefReport(
          userAnswer?.[indexOfBlank]
        )} border border-[#aaaaaa] ${
          fullReport && userAnswer?.[currentQues]?.[indexOfBlank]?.userScore
            ? "border-green-600 bg-green-100"
            : fullReport &&
              userAnswer?.[currentQues]?.[indexOfBlank]?.userScore === 0
            ? "border-red-600 bg-red-100"
            : ""
        } ${
          fullReport ? "font-extrabold" : ""
        } h-9 max-w-full mx-1 inline-block border-black    rounded-lg py-1 px-1 " />
        ${
          fullReport && userAnswer?.[currentQues]?.[indexOfBlank]?.userScore
            ? rightIconBox
            : fullReport &&
              userAnswer?.[currentQues]?.[indexOfBlank]?.userScore === 0
            ? wrongIconBox
            : ""
        } </span> ${
          fullReport &&
          userAnswer?.[currentQues]?.[indexOfBlank]?.userScore !== 1
            ? rightInput
            : ""
        }`;
      });
      // console.log(a);
      $("#txt").html(a);
    }

    let content = $("#txt").html();
    return content;
  };

  return (
    <div
      className="w-full"
      dir={examConfig?.meta_data?.language == "ar" ? "rtl" : "ltr"}
    >
      <div
        id="txt"
        className={`hidden ${
          examConfig?.meta_data?.grade <= 8 ? "font-teshrin" : "font-times"
        }`}
      ></div>
      <div
        id={`${
          examConfig?.meta_data?.grade <= 8
            ? "quest-font-teshrin"
            : "quest-font-times"
        }`}
        
        // Khaled commented on this request via Ahmed
        // onDragExit={props.lang == "ar" ? "ltr" : "rtl"}
        className={` ${
          props.fonts
            ? props.fonts
            : examConfig?.meta_data?.grade <= 8
            ? "font-teshrin"
            : "font-times"
        } text-xl leading-[40px] sm:leading-[50px] ${
          examConfig?.meta_data?.language == "ar" ? "text-right" : "text-left"
        } w-full`}
        dangerouslySetInnerHTML={{
          // __html: resizeImage(props.text, props.type, props.size),
          __html: `<style>${props.style} </style>${resizeImage(
            props.text,
            props.type,
            props.size
          )}`,
        }}
      ></div>
    </div>
  );
};
