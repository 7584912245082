import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
   import { ResizeTable } from "./resizeTable";
import "./styles.css";
import { localStorageAnswered } from './../AglopalQuestionMethod/localStorageAnswered';
 
const getTableHeaders = (data) => {
  if (data.length === 0) return [];
  return Object.keys(data[0]);
};

const Table = ({ question }) => {
  const [data, setData] = useState(question.QuesAns);
  const dispatch = useDispatch();

  const headers = getTableHeaders(data);
  const { currentQues, briefReport, examConfig } = useSelector(
    (state) => state.userAppReducer
  );

  const handleInputChange = (event, id, row, key, right) => {
    const { value } = event.target;

    const updatedData = data.map((row) =>
      row.id === id ? { ...row, [key]: { ...row[key], ans: value } } : row
    );
    setData(updatedData);

    const storedData = JSON.parse(localStorage.getItem(question.QuesID)) || [];
    const itemIndex = storedData.findIndex(
      (item) => item.id === id && item.key === key
    );

    if (itemIndex >= 0) {
      storedData[itemIndex].row.ans = value;
    } else {
      storedData.push({ id, key, row: { row, ans: value, right: right } });
    }

    localStorage.setItem(question.QuesID, JSON.stringify(storedData));
    localStorageAnswered(question.id);
   };

  const getValue = (id, key) => {
    const ansFromLocal = JSON.parse(localStorage.getItem(question.QuesID));
    if (ansFromLocal) {
      const ans = ansFromLocal.find(
        (item) => item?.id === id && item?.key === key
      );
      return ans?.row?.ans;
    }
    return "";
  };

  const addbiefReport = (id, key) => {
    if (!briefReport) {
      return "border-black";
    }
    const ansFromLocal = JSON.parse(localStorage.getItem(question.QuesID));
    if (ansFromLocal) {
      const ans = ansFromLocal.find(
        (item) => item?.id === id && item?.key === key
      );
  //     console.log(ans?.row?.ans);
  //     console.log(ans?.row?.right);
      return ans?.row?.ans === ans?.row?.right && ans
        ? "border-green-500"
        : "border-red-500";
    }
  };

  const extractValue = (value) => {
    if (typeof value === "object" && value !== null && "right" in value) {
      return value.right;
    }
    return value;
  };

  const createInput = (value, id, header) => {
//     console.log(value, id, header);

    if (typeof value === "object" && value !== null && "right" in value) {
      const replacePlaceholdersWithInputs = (text) => {
        const regex = /##(.*?)##/g;
        let match;
        const parts = [];
        let lastIndex = 0;

        while ((match = regex.exec(text)) !== null) {
          const [fullMatch, placeholder] = match;
          if (lastIndex < match.index) {
            parts.push(text.substring(lastIndex, match.index));
          }
          parts.push(
            <input
              key={match.index}
              defaultValue={placeholder}
              style={{ margin: "0 4px" }}
            />
          );
          lastIndex = regex.lastIndex;
        }

        if (lastIndex < text.length) {
          parts.push(text.substring(lastIndex));
        }

        return parts;
      };

      return (
        <input
          type="text"
          onChange={(e) =>
            handleInputChange(e, id, header, header, value.right)
          }
          value={getValue(id, header) || ""}
          disabled={briefReport}
          defaultValue={value.right}
          className={`  ${addbiefReport(
            id,
            header
          )} h-fit border  border-black   p-1 rounded-lg flex-col flex   relative  min-w-[30px]  `}
        />
      );
    }
    return extractValue(value);
    //   return   <ResizeImage
    //   size="rem"
    //   type="ques"
    //   text={value}
    //   question={question}
    // />
  };

  const columnKeys = Array.from(
    new Set(data.flatMap((item) => Object.keys(item)))
  ).filter((key) => key !== "id");
  const columns = columnKeys.map((key) => ({
    title: key, 

    keyProp: key,
  }));




  // const extractParts = (text) => {
//   const regex = /##(.*?)##/g; 

//   const matches = [];
//   let match;
//   while ((match = regex.exec(text)) !== null) {
//     matches.push(match[1]);
//   }
//   return matches;
// };

// const ARR=[]
//   data.map(item => (
//    ARR.push(extractParts(item.txt).length)));

// console.log(ARR);

  
//   const localAnswersStringify = localStorage.getItem(question.id);
//   const localAnswers = localAnswersStringify
//     ? JSON.parse(localAnswersStringify)
//     : Array(data.length).fill([]);

//     localStorage.setItem(question.id, JSON.stringify(localAnswers));


 

 const extractParts = (text) => {
  const parts = [];
  let startIndex = text.indexOf('##');
  while (startIndex !== -1) {
    const endIndex = text.indexOf('##', startIndex + 2);
    if (endIndex === -1) break;
    parts.push(text.substring(startIndex + 2, endIndex));
    startIndex = text.indexOf('##', endIndex + 2);
  }
  return parts;
};


const ARR = data.map(item => extractParts(item.txt).length);

console.log('ARR:', ARR); 



const localAnswers = ARR.map(count => Array(count).fill([]));


const questionId = 'someUniqueQuestionId'; 



const localAnswersStringify = localStorage.getItem(questionId);
const storedAnswers = localAnswersStringify ? JSON.parse(localAnswersStringify) : [];


if (storedAnswers.length === 0) {

  localStorage.setItem(questionId, JSON.stringify(localAnswers));
  console.log('Data stored:', localAnswers);
} else {
  console.log('Data already exists:', storedAnswers);
}


console.log('Stored Answers:', storedAnswers);




//   const localAnswersStringify = localStorage.getItem(question.id);
//   const localAnswers = localAnswersStringify
//     ? JSON.parse(localAnswersStringify)
//     : Array(data.length).fill([]);








  return (
    <div>
      <div className="  flex-row   flex px-2  flex-wrap     p-10  m-5">
        {data.map((item, index) => (
          <>
            <div
              style={{ "--data-length": data.length }}
              className={`font-bold border   text-center     dynamic-width    border-3         `}
            >

              <div  style={{ borderBottom: "4px solid #e5e7eb" }} className="   border-gray-500 pb-3">
                
                <div className="       flex justify-center items-center    m-4  " dangerouslySetInnerHTML={{ __html:question.tableHeder[index]}}/>
              </div>

              <div className=" m-4  ">
                <ResizeTable
                  size="rem"
                  type="ques"
                   questionID={question.id}
                  text={item.txt}
                  indexOfOBJ={index}
                  Item={item}
                  length={data.length}
                />
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default Table;
