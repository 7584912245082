import { createStore, combineReducers, applyMiddleware } from "redux";
 import thunk from "redux-thunk";
import userAppReducer from "./reducers";

const rootReducer = combineReducers({userAppReducer})
// export const store = createStore(rootReducer, applyMiddleware(thunk));

 
 export const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);
