import React, { useRef, useState, useEffect } from "react";
import RectangleComponent from "./RectangleComponent";
import {  useSelector } from "react-redux";
import { localStorageAnswered } from "../AglopalQuestionMethod/localStorageAnswered";
import { setTeacherReport } from './setTeacherReport';
   
const InteractiveBoxes = ({ question,options }) => {
  const canvasRef = useRef(null);
  const [drawnLines, setDrawnLines] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startX, setStartX] = useState(null);
  const [startY, setStartY] = useState(null);
  const [ENDY, setENDY] = useState(null);

  const {   briefReport,endExam,currentQues } = useSelector((state) => state.userAppReducer);
 





  
  const drawSquares = async () => {
    // console.log("drawSquares 🔥");
    const canvas = await canvasRef.current;
    const context = await canvas?.getContext("2d");

    const storedDataEnd = await localStorage.getItem(
      `${question.QuesID}-rectanglePositions-end`
    );
    const storedDataStart = await localStorage.getItem(
      `${question.QuesID}-rectanglePositions-start`
    );

    if (storedDataEnd) {
      const positions = JSON.parse(storedDataEnd);

      positions.forEach((position) => {
        const { start } = position;

        context?.beginPath();
        context.arc(start.x, start.y, 4, 0, 2 * Math.PI);
        context.fillStyle = "black";
        // context.fillStyle =  "rgba(0, 0, 0, 0)";
        context.fill();

        context.beginPath();
        context.arc(start.x, start.y, 4, 0, 2 * Math.PI);
        context.fillStyle = "white";
        context.fill();

         context.strokeStyle = "black";
        context.lineWidth = 2;
         
        context.stroke();
      });
    }

    if (storedDataStart) {
      const positions = JSON.parse(storedDataStart);

      positions.forEach((position) => {
        const { start } = position;

        context?.beginPath();
        context.arc(start.x, start.y, 4, 0, 2 * Math.PI);
        context.fillStyle = "black";
        // context.fillStyle =  "rgba(0, 0, 0, 0)";

        context.fillStyle = "white";
        context.fill();

         context.strokeStyle = "black";
        context.lineWidth = 2; 
         context.stroke();
      });
    }
  };




  
  const drawLines = () => {
     const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    
    const storedDataStart =
      JSON.parse(
        localStorage.getItem(`${question.QuesID}-rectanglePositions-start`)
      ) || [];
    const storedDataEnd =
      JSON.parse(
        localStorage.getItem(`${question.QuesID}-rectanglePositions-end`)
      ) || [];
    const connections =   setTeacherReport(question.QuesID, question.QuesRightAns);

      // JSON.parse(localStorage.getItem(`${question.QuesID}`)) || [];

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    connections?.forEach((connection) => {
      let startPoint, endPoint;

      let TheStrokeStyle;

      if (connection.direction === "start-end") {
        startPoint = storedDataStart[connection.fromIndex]?.start;
        endPoint = storedDataEnd[connection.toIndex]?.start;

        TheStrokeStyle = checkConnectionIsRight(
          question.QuesRightAns,
          connection.fromIndex,
          connection.toIndex
        );
      } else if (connection.direction === "end-start") {
        startPoint = storedDataEnd[connection.fromIndex]?.start;
        endPoint = storedDataStart[connection.toIndex]?.start;
      }

      

      if (startPoint && endPoint) {
        ctx.beginPath();
        ctx.moveTo(startPoint.x, startPoint.y);
        ctx.lineTo(endPoint.x, endPoint.y);
        ctx.strokeStyle = `${!options.fullReport ? "black" : TheStrokeStyle}`;
        ctx.lineWidth = 2;
        ctx.stroke();
      }
    });
  };

  function checkConnectionIsRight(connections, fromValue, toValue) {
    const what = connections.some(
      (connection) =>
        connection.fromIndex === fromValue && connection.toIndex === toValue
    );
    
    return what ? "green" : "red";
  }
  
  
  const checkConnections = () => {
    const storedDataStart =
      JSON.parse(
        localStorage.getItem(`${question.QuesID}-rectanglePositions-start`)
      ) || [];
    const storedDataEnd =
      JSON.parse(
        localStorage.getItem(`${question.QuesID}-rectanglePositions-end`)
      ) || [];

    const isWithinRange = (point, item, range) => {
      // const distance = Math.sqrt(
      //   Math.pow(point.x - item.start.x, 2) + Math.pow(point.y - item.start.y, 2)
      // );

      // return distance <= range;

      const { x, y, width, height } = item.box;

      const withinX = point.x >= x && point.x <= x + width;
      const withinY = point.y >= y && point.y <= y + height;

      return withinX && withinY;
    };

    setDrawnLines((prevLines) => {
      let connections = {};
      if(briefReport===true) return ;

      const updatedLines = prevLines.filter((line) => {
        const startIndexInStart = storedDataStart.findIndex((item) =>
          isWithinRange(line.start, item, 20)
        );
        const endIndexInEnd = storedDataEnd.findIndex((item) =>
          isWithinRange(line.end, item, 20)
        );

        const startIndexInEnd = storedDataEnd.findIndex((item) =>
          isWithinRange(line.start, item, 20)
        );
        const endIndexInStart = storedDataStart.findIndex((item) =>
          isWithinRange(line.end, item, 20)
        );

        const connectionExistsInStartEnd =
          startIndexInStart !== -1 && endIndexInEnd !== -1;
        const connectionExistsInEndStart =
          startIndexInEnd !== -1 && endIndexInStart !== -1;

        const correctConnection =
          connectionExistsInStartEnd || connectionExistsInEndStart;

    //     console.log("--------------------");

        function removeConnectionsFromLocalStorage(
          fromIndex,
          toIndex,
          direction
        ) {
          const storedData =
            JSON.parse(localStorage.getItem(`${question.QuesID}`)) || [];

          const filteredData = storedData.filter(
            (connection) =>
              connection.direction !== direction ||
              (connection.fromIndex !== fromIndex &&
                connection.toIndex !== toIndex)
          );

          localStorage.setItem(
            `${question.QuesID}`,
            JSON.stringify(filteredData)
          );
        }
        localStorageAnswered(question.id)
        if (connectionExistsInStartEnd) {
      //     console.log(startIndexInStart, endIndexInEnd);

          removeConnectionsFromLocalStorage(
            startIndexInStart,
            endIndexInEnd,
            "start-end"
          );
          removeConnectionsFromLocalStorage(
            endIndexInEnd,
            startIndexInStart,
            "end-start"
          );
        }
        if (connectionExistsInEndStart) {
          removeConnectionsFromLocalStorage(
            startIndexInEnd,
            endIndexInStart,
            "end-start"
          );
          removeConnectionsFromLocalStorage(
            endIndexInStart,
            startIndexInEnd,
            "start-end"
          );
        }
        if (connectionExistsInStartEnd) {
          connections = {
            fromIndex: startIndexInStart,
            toIndex: endIndexInEnd,
            direction: "start-end",
          };
        } else if (connectionExistsInEndStart) {
          connections = {
            fromIndex: startIndexInEnd,
            toIndex: endIndexInStart,
            direction: "end-start",
          };
        }

        return correctConnection;
      });

      if (connections.fromIndex >= 0) {
        if (
          !checkSpecificDuplicate(
            connections.direction,
            connections.fromIndex,
            connections.toIndex
          )
        ) {
          // console.log("not Duplicate 🔥");
          if (
            checkPointUsageInDirection(
              connections.direction,
              connections.fromIndex,
              connections.toIndex
            )
          ) {
            addObjectToLocalStorageArray(connections);
          }
        }
      }

      return updatedLines;
    });

    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    drawSquares();
    drawLines();
  };

  const checkPointUsageInDirection = (direction, from, to) => {
    const storedArray = localStorage.getItem(`${question.QuesID}`);

    const array = storedArray ? JSON.parse(storedArray) : [];

    if (direction === "start-end") {
      const what = array.some((item) => {
        return (
          (item.direction === "start-end" &&
            (item.fromIndex === from || item.toIndex === to)) ||
          (item.direction === "end-start" &&
            (item.fromIndex === to || item.toIndex === from))
        );
      });

      return !what;
    } else if (direction === "end-start") {
      const what = array.some((item) => {
        return (
          (item.direction === "start-end" &&
            (item.fromIndex === to || item.toIndex === from)) ||
          (item.direction === "end-start" &&
            (item.fromIndex === from || item.toIndex === to))
        );
      });

      return !what;
    }
  };
  const addObjectToLocalStorageArray = async (newObject) => {
//     console.log(newObject);

    const storedArray = localStorage.getItem(`${question.QuesID}`);

    let array = storedArray ? JSON.parse(storedArray) : [];

    const { fromIndex, toIndex, direction } = newObject;

    const existingIndex = array.findIndex(
      (connection) =>
        (connection.fromIndex === fromIndex ||
          connection.toIndex === toIndex) &&
        connection.direction === direction
    );

    if (existingIndex !== -1) {
      array.splice(existingIndex, 1);
    }

//     console.log(array);

    array.push(newObject);
    if(briefReport===true) return ;
    localStorage.setItem(`${question.QuesID}`, JSON.stringify(array));
    localStorageAnswered(question.id)

    drawSquares();
    drawLines();
  };

  const checkSpecificDuplicate = (direction, fromIndex, toIndex) => {
    // console.log(direction, fromIndex, toIndex);
    const storedData = localStorage.getItem(`${question.QuesID}`);

    const storedArray = storedData ? JSON.parse(storedData) : [];

    const hasDuplicate = storedArray.some((item) => {
      return (
        item.direction === direction &&
        item.fromIndex === fromIndex &&
        item.toIndex === toIndex
      );
    });
    // console.log(hasDuplicate);
    // hasDuplicate && console.log("XXXXXXXXXXXXXXXXXX SpecificDuplicate 😢🎶");
    return hasDuplicate;
  };

  const clearCanvas = () => {
 
    drawSquares();
    drawLines();
  };

  const loadLines = () => {
 
    drawSquares();
    drawLines();
 
  };

  const handleMouseDown = (e) => {
    // console.log("handleMouseDown 🔥");
    // briefReport;
    // endExam;
    const rect = canvasRef.current?.getBoundingClientRect();
    setIsDrawing(true);
    setStartX(e.clientX - rect.left);
    setStartY(e.clientY - rect.top);
  };

  const handleMouseMove = (e) => {

    if(briefReport===true) return ;


    if (isDrawing) {
      const rect = canvasRef.current?.getBoundingClientRect();
      const endX = e.clientX - rect.left;
      const endY = e.clientY - rect.top;

      const canvas = canvasRef.current;
      const ctx = canvas?.getContext("2d");

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawSquares();
      drawLines();

      ctx.strokeStyle = "black";
      ctx.lineWidth = 3;
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.stroke();
    }
  };

  const handleMouseUp = (e) => {
    // console.log("handleMouseUp 🔥");

    if (isDrawing) {
      const rect = canvasRef.current?.getBoundingClientRect();
      const endX = e.clientX - rect.left;
      const endY = e.clientY - rect.top;
      setENDY(endY);
      setDrawnLines([
        {
          start: { x: startX, y: startY },
          end: { x: endX, y: endY },
          color: "blue",
        },
      ]);

      setIsDrawing(false);
      checkConnections();
      clearCanvas();
      // console.log("============-----drawnLines");
      // console.log(drawnLines);
      //       localStorage.setItem("drawnLines", JSON.stringify(drawnLines));
    }
  };

  useEffect(() => {
    drawLines();
  }, [ENDY]);

  const divRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const element = divRef.current;
    if (element) {
      const { width, height } = element?.getBoundingClientRect();
      setDimensions({ width, height });
    }
  }, []);

  function printWindowWidth() {
    const outerWidth = window.outerWidth;
    const innerWidth = window.innerWidth;
    const outerHeight = window.outerHeight;
    const innerHeight = window.innerHeight;

     const devToolsWidth = outerWidth - innerWidth;
    const devToolsHeight = outerHeight - innerHeight;

     const actualWidth = devToolsWidth > 100 ? innerWidth : outerWidth;
    const actualHeight = devToolsHeight > 100 ? innerHeight : outerHeight;

    // console.log("Actual window width:", actualWidth);
    // console.log("Actual window height:", actualHeight);
    return [actualWidth, actualHeight];
  }
  function checkOrientation() {
    const orientation = window.orientation;

    if (orientation === 0 || orientation === 180) {
       return "Portrait";
    } else if (orientation === 90 || orientation === -90) {
       return "Landscape";
    } else {
       return "cant";
    }
  }

  const myElementRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    // console.log("0---------------0");

    if (myElementRef.current) {
      const rect = myElementRef.current?.getBoundingClientRect();
      setCanvasSize({ width: rect.width, height: rect.height });
    }

    checkConnections();
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    loadLines();
    drawSquares();
  }, [
    printWindowWidth()[0],
    printWindowWidth()[1],
    checkOrientation(),
    // myElementRef,
    canvasSize.width,question.QuesID
  ]);

  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const element = myElementRef.current;
    if (element) {
      const rect = element?.getBoundingClientRect();
      setCoordinates({
        x: rect.x,
        y: rect.y,
      });
    }
    drawSquares();
    loadLines();
  }, [myElementRef, canvasSize,question.QuesID]);

  const [start, setstart] = useState(1);
  const [message, setMessage] = useState("");
  const [sizeELEMENT, setSizeElement] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleLoad = () => {
      setstart(start + 1);
    };

    handleLoad();

    return () => {};
  }, []);
  useEffect(() => {
    drawSquares();
    loadLines();
    drawLines();
  }, [start, sizeELEMENT]);
  useEffect(() => {
    drawSquares();

    // console.log("message-------");
    // console.log(message);
  }, [start, message.width, sizeELEMENT]);

  const handleMessageChange = (newMessage) => {
    setMessage(newMessage);
    // console.log(newMessage);
  };

  useEffect(() => {
    const element = myElementRef.current;

    const handleResize = () => {
      if (element) {
        setSizeElement({
          width: element.clientWidth,
          height: element.clientHeight,
        });
      }
    };

    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(element);

    // Add event listener for mouse move on document
    document.addEventListener("mousemove", handleMouseMove);

    // Clean up function
  }, []);

  const removeLastItem = () => {
    const storedArray = localStorage.getItem(`${question.QuesID}`);
    console.log(storedArray);

    if (storedArray) {
      let dataArray = JSON.parse(storedArray);

      dataArray.pop();

      localStorage.setItem(`${question.QuesID}`, JSON.stringify(dataArray));
      localStorageAnswered(question.id)

      console.log("Last item removed:", dataArray);
    } else {
      console.log("No data found in localStorage");
    }

    drawSquares();
    drawLines();
  };

  const handleTouchStart = (e) => {
    // e.preventDefault(); 

    
    // document.body.classList.remove('overflow-y-hidden');
    document.body.classList.add("overflow-y-hidden");

    const touch = e.touches[0];
    const rect = canvasRef.current?.getBoundingClientRect();
    setIsDrawing(true);
    setStartX(touch.clientX - rect.left);
    setStartY(touch.clientY - rect.top);
  };

  const handleTouchMove = (e) => {
    if(briefReport===true) return ;

    if (isDrawing) {
      e.preventDefault();
      const touch = e.touches[0];
      const rect = canvasRef.current?.getBoundingClientRect();
      const endX = touch.clientX - rect.left;
      const endY = touch.clientY - rect.top;

      const canvas = canvasRef.current;
      const ctx = canvas?.getContext("2d");

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawSquares();
      drawLines();

      ctx.strokeStyle = "black";
      ctx.lineWidth = 5;
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.stroke();
    }
  };

  const handleTouchEnd = (e) => {
    if (isDrawing) {
      e.preventDefault();
      const touch = e.changedTouches[0];
      const rect = canvasRef.current?.getBoundingClientRect();
      const endX = touch.clientX - rect.left;
      const endY = touch.clientY - rect.top;
      setENDY(endY);
      setDrawnLines([
        {
          start: { x: startX, y: startY },
          end: { x: endX, y: endY },
          color: "blue",
        },
      ]);

      setIsDrawing(false);
      checkConnections();
      clearCanvas();
    }
  };










 
  
 
  
 


 
  return (
       <div
        ref={myElementRef}
        className=" relative    rounded-b-xl  shadow-lg "
      >
          
                 <span
        className="font-['roman'] rtl:font-['Droid'] text-left rtl:text-right font-wrap normal-case"
        dangerouslySetInnerHTML={{ __html: question.QuesTxt }}
        /> 
               <div className="p-4">

        <canvas
          ref={canvasRef}
          width={canvasSize.width}
          height={canvasSize.height}
          className="    absolute top-0 left-0 z-[999]   "
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          ></canvas>

        <div className="   flex justify-between  flex-row  ">
          <RectangleComponent
            QuesID={question.QuesID}
            coordinates={coordinates}
            onMessageChange={handleMessageChange}
            dir="end"
            data={question.QuesAns.liftColumn}
            />

          <RectangleComponent
            QuesID={question.QuesID}
            coordinates={coordinates}
            onMessageChange={handleMessageChange}
            dir="start"
            data={question.QuesAns.rightColumn}
            />
        </div>
            </div>
      </div>
   );
};

export default InteractiveBoxes;
