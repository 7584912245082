//non
//Pagination
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentQues, setUserAnswer } from "../store/actions";
import { useParams } from "react-router-dom";
import $ from "jquery";

export const Pagination = (props) => {
  // console.log(props);

  const params = useParams();
  const dispatch = useDispatch();
  // @ts-ignore
  const { currentQues, userAnswer, fullReport, briefReport } = useSelector(
    (state) => state.userAppReducer
  );

  const setCurentQues = (index) => {
    // @ts-ignore
    //
    dispatch(setCurrentQues(index));
    // paragraphBody
    if ($("#paragraphBody")) {
      $("#paragraphBody").scrollTop(0, 0);
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    updateAnswer();
  }, [currentQues]);

  const updateAnswer = async () => {
    let localAnswers = await localStorage.getItem("userAnswer_" + params.id);
    localAnswers = JSON.parse(localAnswers);
    dispatch(setUserAnswer(localAnswers ? localAnswers : []));
  };

  const checkIfParagraphAnswer = (index) => {
    let list = 0;
    if (userAnswer[index] && typeof userAnswer[index] == "object") {
      userAnswer[index].map((e) => {
        if (e && e[0].score === e[0].userScore) {
          list++;
        }
      });
    }
    return list;
  };

  const AnswerNumber = ({ index }) => {
    //
    let current_is_answerd = userAnswer.filter(
      (e) => e !== null && e.index == index
    )[0];

    const getBlanksAnswerd = (index) => {
      const ans = userAnswer[index];
      //
      if (!ans) return 0;
      let help = [];
      let totalAns = 0;
      ans.map((q) => {
        help.push(q?.userScore);
        totalAns = q?.totalAns;
      });

      if (!help.includes(0) && help.length === totalAns) {
        return 1;
      } else if (help.includes(1)) {
        return 2;
      } else {
        return 3;
      }
    };

    //
    return (
      <div
        className={`${
          currentQues === index
            ? "w-10 h-10 border-2 border-primary rounded-md flex  justify-center items-center align-middle"
            : "a"
        }`}
      >
        <button
          type="button"
          onClick={() => setCurentQues(index)}
          className={`${
            currentQues === index ? "w-8 h-8" : "w-10 h-10"
          } select-none 
                        ${
                          props.type[index].QuesType !== "mcq"
                            ? props.type[index].QuesType === "Paragraph"
                              ? checkIfParagraphAnswer(index) ===
                                props.type[index].list.length
                                ? "bg-[#288D57]"
                                : checkIfParagraphAnswer(index) > 0
                                ? "bg-[#f1964a]"
                                : "bg-[#AB2620]"
                              : props.type[index].QuesType === "essay"
                              ? current_is_answerd &&
                                current_is_answerd.userScore >=
                                  current_is_answerd.score / 2
                                ? "bg-[#288D57]"
                                : "bg-[#AB2620]"
                              : props.type[index].QuesType === "blanks"
                              ? getBlanksAnswerd(index) == 1
                                ? "bg-[#288D57]"
                                : getBlanksAnswerd(index) == 2
                                ? "bg-[#f1964a]"
                                : "bg-[#AB2620]"
                              : "bg-[#f1964a]"
                            : current_is_answerd &&
                              (current_is_answerd.userScore ===
                                current_is_answerd.score ||
                                current_is_answerd.answer ===
                                  current_is_answerd.right)
                            ? "bg-[#288D57]"
                            : "bg-[#AB2620]"
                        } text-white font-['roman'] rtl:font-['ArabicEqu'] rounded-md flex  justify-center items-center align-middle relative`}
        >
          <IconBox
            active={
              current_is_answerd ||
              (props.type[index].QuesType === "blanks" &&
                getBlanksAnswerd(index) !== 0)
                ? true
                : false
            }
            right={
              (current_is_answerd &&
                (current_is_answerd.userScore >= current_is_answerd.score / 2 ||
                  current_is_answerd.answer === current_is_answerd.right)) ||
              (props.type[index].QuesType === "blanks" &&
                getBlanksAnswerd(index) === 1)
                ? true
                : false
            }
          />
          {index + 1}
        </button>
      </div>
    );
  };

  const IconBox = ({ right, active }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={`
                    w-5 h-5 border -top-2 -right-2 z-10 absolute bg-white rounded-full
                    ${right === true ? "stroke-green-600" : "stroke-red-600"}
                    ${active === false ? "hidden" : ""}
                `}
      >
        {right == true ? (
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 12.75l6 6 9-13.5"
          />
        ) : (
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        )}
      </svg>
    );
  };

 
  return (
    <div className="w-full flex flex-row justify-center flex-wrap gap-3  px-2 py-5">
       
      {Array.apply(null, { length: props.type.length }).map((element, index) =>
        fullReport === true && briefReport === false ? (
          <AnswerNumber key={index} index={index} />
        ) : (
          <button
            key={index}
            type="button"
            onClick={() => setCurentQues(index)}
            className={`w-10 h-10 relative select-none ${
              currentQues === index
                ? "bg-primary text-white"
                : userAnswer[index]
                ? " bg-opacity-20 text-[#151048] bg-primary"
                : "text-[#151048] bg-white"
            }
                            border-primary font-['roman'] rtl:font-['ArabicEqu'] border rounded-md flex  justify-center items-center align-middle`}
          >
            {index + 1}
          </button>
        )
      )}
    </div>
  );
};
