import React, { useState, useEffect } from "react";

const SpellingPractice = ({ targetWord, inputWord }) => {
  const [result, setResult] = useState([]);

  useEffect(() => {
       compareWords(inputWord);
  }, []);

  const compareWords = async  (input) => {
    const resultArray = [];
    for (let i = 0; i < inputWord.length; i++) {
      if (input[i] === targetWord[i]) {
        resultArray.push({ char: targetWord[i], correct: true });
      } else if (input[i]) {
        resultArray.push({ char: input[i], correct: false });
      } else {
        resultArray.push({ char: "_", correct: false });
      }
    }
    setResult(resultArray);
  };

  return (
    <div
      className={` px-5 border-2 ${
        targetWord === inputWord ? "border-green-500" : "border-red-300"
      }     rounded-lg     p-1  w-full flex flex-row  justify-between`}
    >
      <div>
        <div>
          {targetWord !== inputWord ? (
            result.map((item, index) => (
              <>
                <span
                  key={index}
                  className={item.correct ? "none" : " font-bold	"}
                  style={{
                    color: item.correct ? "green" : "red",
                    //    textDecoration: item.correct ? 'none' :  "underline overline",
                  }}
                >
                  {item.char}
                </span>
                <span></span>
              </>
            ))
          ) : (
            <></>
          )}
        </div>

        <input
          className="text-green-600 font-bold"
          type="text"
          value={targetWord}
        />
      </div>

      <div className="  l flex flex-col justify-center items-center ">
        {targetWord === inputWord ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 text-green-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 text-red-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default SpellingPractice;
