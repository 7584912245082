import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { NavBar } from "../components";
import langConfig from "../sql/config.json";
 import axios from "axios";

export const Subjects = (props) => {
  const params = useParams();
  // const isAuthenticated = useIsAuthenticated()
   const { lang } = useSelector((state) => state.userAppReducer);
  const [subjectsList, setSubjectsList] = useState([]);
  const [mainBooks, setMainBooks] = useState([]);
  // const [activeLogin, setActiveLogin] = useState((isAuthenticated() === false && localStorage.getItem("userLogin") || !localStorage.getItem("userLogin") || localStorage.getItem("userLogin") === false) ? true : false);
  const [loading, setLoading] = useState(true);
  const [accessable, setAccessable] = useState(false);
  const [currentDivision, setCurrentDivision] = useState(
    localStorage.getItem("division")
  );
  useEffect(() => {
    checkDivision();
    // dispatch(setBlur((isAuthenticated() === false && localStorage.getItem("userLogin") || !localStorage.getItem("userLogin") || localStorage.getItem("userLogin") === false) ? true : false))
    getSubjects();
  }, [params]);

  const checkDivision = () => {
    if (currentDivision) {
      if (currentDivision === `${params.school}`) {
        setAccessable(true);
      } else {
        setAccessable(false);
      }
    } else {
    }
  };

  const mytoken=localStorage.getItem("token")
  const headers = {
    "access-token": "qwertasdfg123@+1",
    Authorization:      `Bearer ${mytoken}`,
   };

  const getSubjects = async () => {
    await axios
      .get(`https://emmobank.com/api/get_subjects?grade_id=${params.school}&division_id=${params.division}`, {
        headers: headers,
      })
      .then(async (respons) => {
    //     console.log("respons");
    //     console.log(respons.data);
        setMainBooks(respons.data);
        let list = [];

        setSubjectsList(respons.data.subjects.sort((a, b) => a.sort - b.sort));
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const SubjectBox = ({ item }) => {
    const givenDate = new Date(item.date);
    const today = new Date();
    const diffInTime = givenDate.getTime() - today.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24)) + 1;
    return item.active === true && diffInDays <= 0 ? (
      <Link to={"/lessons/"+params.school+"/"+ item.id}>
        <div className="bg-white border shadow-md rounded-lg p-2 flex flex-row gap-5 justify-center items-center">
          <img
            src={item.image}
            alt={item.name}
            className="flex w-20 h-20 shrink-0"
          />
          <div className="w-full flex flex-col h-full    justify-start items-start content-start overflow-auto">
            <div className="text-[18px] font-bold p-0  text-[#151048] font-[Almarai] text-start">
              {item.name}
            </div>
       
          </div>
        </div>
      </Link>
    ) : (
      <div className="bg-white border shadow-md rounded-lg p-2 flex flex-row gap-5 justify-start items-center relative z-0">
        <img
          src={item.image}
          alt={item.name}
          className="flex w-20 h-20 shrink-0"
        />
        <div className="w-full flex flex-col h-full gap-2  justify-start items-start content-start overflow-auto">
          <h1 className="text-[18px] font-bold text-[#151048] font-[Almarai] text-start">
            {item.name}
          </h1>
          <p className="text-sm text-gray-400 font-[Almarai]">
            <span className="font-[ArabicEqu]">{item.count} </span>
            {langConfig.config[lang].exams}
          </p>
        </div>
        <div className="bg-gray-400 rounded-full absolute bottom-3 left-3 px-3 py-1 text-[12px] text-white">
          سيتوفر في{" "}
          <span dir="rtl" className="font-[ArabicEqu]">
            {item.date}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      dir={lang === "ar" ? "rtl" : "ltr"}
      className={`flex flex-col justify-start h-full w-full p-0   md:max-w-[875px] m-auto overflow-hidden`}
    >
       <NavBar
        menu={true}
        qr={false}
        back={false}
        title={true}
        titleText={"تدريبات 2024 - 2025"}
      />
      {loading ? (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-12 h-12 m-auto mt-32 flex animate-spin stroke-primary"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        </>
      ) : (
        <>
          <div className={`flex flex-col gap-3 w-full h-fit px-4 py-3`}>
            {
              subjectsList.map((e, index) => (
                <SubjectBox key={index} item={e} />
              ))
            }
            <div className="h-8"></div>
          </div>
        </>
      )}
    </div>
  );
};
