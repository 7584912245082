import React from "react";

export const QuesHead = (props) => {
  const { head, number, quesTxt } = props;
  // console.log(props);

  const replacementTitle = (text) => {
    let last = "";
    text?.split(":")?.map((e) =>
        e && (e !== "" || e !== "undefined") ? (last += e + "<bdi>:</bdi>") : ""
      );
    return last;
  };

  return (
    <div
      className="flex flex-row justify-start gap-1 text-primaryFont text-md font-[roman] rtl:font[Droid] font-bold bg-tertiary p-2 rounded-t-xl"
      style={{ letterSpacing: -1 }}
    >
   <div className="flex flex-col">
    <div>

      <span className="font-['roman']  shrink-0 rtl:font-['ArabicEqu']">
        {number} :{" "}
      </span>
   <span
        className="font-['roman'] rtl:font-['Droid'] text-left rtl:text-right font-wrap normal-case"
        dangerouslySetInnerHTML={{ __html: replacementTitle(head) }}
        /> 
        </div>
      <div
        className="font-['roman'] rtl:font-['Droid'] text-left rtl:text-right font-wrap normal-case"
        dangerouslySetInnerHTML={{ __html:quesTxt }}
      /> 
   </div>
    </div>
  );
};
