export function setTeacherReport(storageKey, connectionsArray) {
    const updatedConnections = connectionsArray.map(connection => {
      return {
        ...connection, 

        direction: "end-start" 
      };
    });
  

    return updatedConnections
    // localStorage.setItem(storageKey, JSON.stringify(updatedConnections));
  
  }
  
 
  
  