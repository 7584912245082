import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { Flip, toast } from "react-toastify";

const firebaseConfig = {
  apiKey: "AIzaSyA_VFc4oIfDAGjHRE-vbyxLMxHRWexd0ZA",
  authDomain: "http://emmo-bank.firebaseapp.com/",
  projectId: "emmo-bank",
  storageBucket: "http://emmo-bank.appspot.com/",
  messagingSenderId: "43975706991",
  appId: "1:43975706991:web:4c766a2636e4b68ee7c09a",
  measurementId: "G-JGP5S2GN0B",
};





const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();
    
    if (permission === 'granted') {
       
      // Get the FCM token
      const token = await getToken(messaging);
       
      localStorage.setItem('fcm_token', token);
    } else {
       
    }
    // Handle foreground notifications
    onMessage(messaging, (payload) => {
       


  const questionId=payload?.data?.question_id
         
      var baseUrl = window.location.origin;
          var targetUrl = baseUrl + `/ReviewnotificationQuestions/${questionId}`;




          toast.error(`اشعار جديد `, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: 1,
              theme: "colored",
              
            transition: Flip,
            onClick: () => window.location.href = `${targetUrl}`,
           });



  

      // Handle the notification or update your UI
    });
  } catch (error) {
    console.log('Error setting up notifications:', error.message);
  }
};
export { messaging, setupNotifications };